body {
  overflow-y: scroll;
  font-family: "Lato", sans-serif;
  font-size: 16px;
  color: #555;
}
body .main-wrapper {
  display: flex;
  flex-flow: column;
  min-height: 100vh;
}
body .to-load {
  flex-grow: 1;
}

@media (max-width: 991px) {
  .container {
    width: 100%;
  }
}

.to-load {
  overflow: hidden;
}

a,
a:hover,
a:focus {
  text-decoration: none;
  outline: none;
}

img {
  max-width: 100%;
}

.of-c {
  object-fit: cover;
}

.cb {
  clear: both;
}

.b0 {
  border: 0 !important;
}

.br0 {
  border-radius: 0 !important;
}

.rounded {
  border-radius: 100%;
}

.tc-grey {
  color: #555;
}

.tc-white {
  color: #ffffff;
}

.tc-blue {
  color: #184188;
}

.d-flex {
  display: flex;
}

.d-block {
  display: block;
}

.d-ib {
  display: inline-block;
}

.flex-1 {
  flex: 1;
}

/* OVERFLOW */
.o-hidden {
  overflow: hidden;
}

.o-inherit {
  overflow: inherit;
}

/* FONT WEIGHT */
.fw300 {
  font-weight: 300;
}

.fw400 {
  font-weight: 400;
}

.fw600 {
  font-weight: 600;
}

.fw700 {
  font-weight: 700;
}

.fw800 {
  font-weight: 800;
}

.h100 {
  height: 100%;
}

.lh1-7 {
  line-height: 1.7;
}

.lh30 {
  line-height: 30px;
}

.p0 {
  padding: 0 !important;
}

.p5 {
  padding: 5px;
}

.p10 {
  padding: 10px;
}

.p15 {
  padding: 15px;
}

.p20 {
  padding: 20px;
}

.p30 {
  padding: 30px;
}

.p50 {
  padding: 50px;
}

.p2010 {
  padding: 20px 10px;
}

.p1010 {
  padding: 10px 10px;
}

.px-5 {
  padding: 0 5px;
}

.px-10 {
  padding: 0 10px;
}

.px-15 {
  padding: 0 15px;
}

.px-20 {
  padding: 0 20px;
}

.px-30 {
  padding-left: 30px;
  padding-right: 30px;
}

.py-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.py-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.py-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.pl-0 {
  padding-left: 0;
}

.pr-0 {
  padding-right: 0;
}

.m0 {
  margin: 0;
}

.mt0 {
  margin-top: 0;
}

.mt10 {
  margin-top: 10px;
}

.mt15 {
  margin-top: 15px;
}

.mt20 {
  margin-top: 20px;
}

.mt30 {
  margin-top: 30px;
}

.mb0 {
  margin-bottom: 0 !important;
}

.mb5 {
  margin-bottom: 5px;
}

.mb10 {
  margin-bottom: 10px;
}

.mb15 {
  margin-bottom: 15px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb30 {
  margin-bottom: 30px;
}

.mb35 {
  margin-bottom: 35px;
}

.mb40 {
  margin-bottom: 40px;
}

.row15- {
  margin: 0 -7.5px;
}

.row-5 {
  margin: 0 -5px;
}

.row-10 {
  margin: 0 -10px;
}

.row-20 {
  margin: 0 -20px;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.my20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.my40 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.ps-absolute {
  position: absolute;
}

.ps-relative {
  position: relative;
}

.ps-static {
  position: static;
}

.tt-uppercase {
  text-transform: uppercase;
}

.tt-lowercase {
  text-transform: lowercase;
}

.w10 {
  width: 10%;
}

.w20 {
  width: 20%;
}

.w30 {
  width: 30%;
}

.w40 {
  width: 40%;
}

.w50 {
  width: 50%;
}

.w60 {
  width: 60%;
}

.w70 {
  width: 70%;
}

.w80 {
  width: 80%;
}

.w90 {
  width: 90%;
}

.w100 {
  width: 100%;
}

.fs14 {
  font-size: 14px;
}

.fs15 {
  font-size: 15px;
}

.fs16 {
  font-size: 16px;
}

.fs17 {
  font-size: 17px;
}

.fs18 {
  font-size: 18px;
}

.fs20 {
  font-size: 20px;
}

.fs22 {
  font-size: 22px;
}

.fs25 {
  font-size: 25px;
}

.fs28 {
  font-size: 28px;
}

.fs29- {
  font-size: 29.5px;
}

.fs30 {
  font-size: 30px;
}

.fs40 {
  font-size: 40px;
}

.fs45 {
  font-size: 45px;
}

.fs16pt {
  font-size: 16pt;
}

.fs22pt {
  font-size: 22pt;
}

.fs25pt {
  font-size: 25pt;
}

.fs30pt {
  font-size: 30pt;
}

.bgc-lightgrey {
  background-color: #eeeeee;
}

.bgc-grey {
  background-color: #ececec;
}

.bgc-blue {
  background-color: #184188;
}

.bgc-lightblue {
  background-color: #96b7c4;
}

.bgc-darkgrey {
  background-color: #e2e2e2;
}

.bgc-light-grey {
  background-color: #ebe9e9;
}

.ai-c {
  align-items: center;
}

.jc-c {
  justify-content: center;
}

.ff-c {
  flex-flow: column;
}

.fw-w {
  flex-wrap: wrap;
}

.align-item-end {
  align-items: flex-end;
}

@media (max-width: 479px) {
  .xxs-full {
    width: 100%;
  }
}

.has-bg-cloudy {
  background-image: url("/img/filinvest/cloudy.jpg");
  background-size: cover;
  background-position: center;
}
@media (max-width: 991px) {
  .has-bg-cloudy {
    background-size: auto 100%;
    background-position: center top;
  }
}

.cloudy {
  position: absolute;
  left: 0;
  right: 0;
  margin: -15px;
}

@media (max-width: 992px) {
  .wow {
    visibility: visible !important;
    -webkit-animation: none !important;
    -moz-animation: none !important;
    -o-animation: none !important;
    -ms-animation: none !important;
    animation: none !important;
  }
}
lesshat-selector {
  -lh-property: 0;
}

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes rotate {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-o-keyframes rotate {
  0% {
    -o-transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(360deg);
  }
}
@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
[not-existing] {
  zoom: 1;
}

lesshat-selector {
  -lh-property: 0;
}

@-webkit-keyframes fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.25;
  }
}
@-moz-keyframes fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.25;
  }
}
@-o-keyframes fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.25;
  }
}
@keyframes fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.25;
  }
}
[not-existing] {
  zoom: 1;
}

lesshat-selector {
  -lh-property: 0;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
[not-existing] {
  zoom: 1;
}

table.restables-clone {
  display: none;
}

@media (max-width: 991px) {
  table.restables-origin {
    display: none;
  }

  table.restables-clone {
    display: table;
  }
  table.restables-clone tr:nth-child(even) {
    background-color: #f5f5f5;
  }
}
.alternate:nth-of-type(4n+1) {
  animation-delay: 0.1s;
}

.alternate:nth-of-type(4n+2) {
  animation-delay: 0.2s;
}

.alternate:nth-of-type(4n+3) {
  animation-delay: 0.3s;
}

.alternate:nth-of-type(4n+4) {
  animation-delay: 0.4s;
}

/* ALERTS */
.alert-dismissable .close, .alert-dismissible .close {
  right: 0;
}

.alert {
  margin: 20px 0;
  padding: 20px;
  border-radius: 0;
  border-left: solid #eee;
  border-width: 0 0 0 3px;
}

.alert h4 {
  margin-top: 0;
  margin-bottom: 5px;
}

.alert p:last-child {
  margin-bottom: 0;
}

.alert-message code {
  background-color: #fff;
  border-radius: 3px;
}

.alert-success {
  background-color: #F4FDF0;
  border-color: #3C763D;
}

.alert-success h4 {
  color: #3C763D;
}

.alert-danger {
  background-color: #fdf7f7;
  border-color: #d9534f;
}

.alert-danger h4 {
  color: #d9534f;
}

.alert-warning {
  background-color: #fcf8f2;
  border-color: #f0ad4e;
}

.alert-warning h4 {
  color: #f0ad4e;
}

.alert-info {
  background-color: #f4f8fa;
  border-color: #5bc0de;
}

.alert-info h4 {
  color: #5bc0de;
}

.alert-default {
  background-color: #EEE;
  border-color: #B4B4B4;
}

.alert-default h4 {
  color: #000;
}

.alert-notice {
  background-color: #FCFCDD;
  border-color: #BDBD89;
}

.alert-notice h4 {
  color: #444;
}

.user-profile {
  /* NAV-TABS */
}
.user-profile .nav-tabs {
  border-bottom: 2px solid #ddd;
}
.user-profile .nav-tabs > li.active > a,
.user-profile .nav-tabs > li.active > a:focus,
.user-profile .nav-tabs > li.active > a:hover {
  border-width: 0;
}
.user-profile .nav-tabs > li > a {
  color: #666;
  border: none;
}
.user-profile .nav-tabs > li.active > a,
.user-profile .nav-tabs > li > a:hover,
.user-profile .nav-tabs > li > a:focus {
  color: #E1A04B !important;
  border: none;
  background: transparent;
}
.user-profile .nav-tabs > li > a::after {
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 2px;
  content: "";
  transition: all 250ms ease 0s;
  transform: scale(0);
  background-color: #E1A04B;
}
.user-profile .nav-tabs > li.active > a::after,
.user-profile .nav-tabs > li:hover > a::after,
.user-profile .nav-tabs > li > a:focus::after {
  transform: scale(1);
}
.user-profile .tab-nav > li > a::after {
  color: #fff;
  background-color: #21527d;
}
.user-profile .tab-pane {
  padding: 15px 0;
}

noscript.ns-message {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  color: #184188;
  background-color: #ffffff;
  text-align: center;
}

.flexslider .item .item--content {
  -webkit-animation: fade-out 1.8s ease-out both 0.3s both;
  animation: fade-out 1.8s ease-out both 0.3s both;
}

.flexslider .flex-active-slide .item--content {
  -webkit-animation: fade-in 2s cubic-bezier(0.39, 0.575, 0.565, 1) 0.5s both;
  animation: fade-in 2s cubic-bezier(0.39, 0.575, 0.565, 1) 0.5s both;
}

@-webkit-keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.front .flex-active-slide img {
  -webkit-animation: slide-image-animation 1.5s ease-out alternate-reverse both;
  animation: slide-image-animation 1.5s ease-out alternate-reverse both;
}

@-webkit-keyframes slide-image-animation {
  0% {
    -webkit-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  100% {
    -webkit-transform: scale(1.15) translateY(0);
    transform: scale(1.15) translateY(0);
    -webkit-transform-origin: center;
    transform-origin: center;
  }
}
@keyframes slide-animation {
  0% {
    -webkit-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  100% {
    -webkit-transform: scale(1.15) translateY(0px);
    transform: scale(1.15) translateY(0px);
    -webkit-transform-origin: center;
    transform-origin: center;
  }
}
.paragraph-item-show.paragraph-type-carousel .slick-slider {
  min-height: 500px;
}
@media screen and (max-width: 1199px) {
  .paragraph-item-show.paragraph-type-carousel .slick-slider {
    min-height: 200px;
  }
}
.paragraph-item-show.paragraph-type-carousel .slick-arrow {
  position: absolute;
  top: 50%;
  background-color: #0C2144;
  color: #fff;
  min-height: 50px;
  border: 0;
  font-size: 0;
  opacity: 0.45;
  top: 40%;
  padding: 15px;
  transition: all ease 300ms;
  z-index: 2;
}
@media screen and (max-width: 480px) {
  .paragraph-item-show.paragraph-type-carousel .slick-arrow {
    top: 30%;
  }
}
.paragraph-item-show.paragraph-type-carousel .slick-arrow:hover {
  opacity: 1;
  transition: all ease 300ms;
}
.paragraph-item-show.paragraph-type-carousel .slick-arrow::before {
  display: inline-block;
  font-family: Fontawesome;
  font-size: 50px;
}
.paragraph-item-show.paragraph-type-carousel .slick-arrow.slick-prev {
  left: 20%;
}
.paragraph-item-show.paragraph-type-carousel .slick-arrow.slick-prev::before {
  content: "\f104";
}
@media screen and (max-width: 480px) {
  .paragraph-item-show.paragraph-type-carousel .slick-arrow.slick-prev {
    left: 5vw;
  }
}
@media screen and (min-width: 1440px) {
  .node-type-projects .paragraph-item-show.paragraph-type-carousel .slick-arrow.slick-prev {
    left: 21%;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1439px) {
  .node-type-projects .paragraph-item-show.paragraph-type-carousel .slick-arrow.slick-prev {
    left: 15vw;
  }
}
@media screen and (max-width: 1199px) {
  .node-type-projects .paragraph-item-show.paragraph-type-carousel .slick-arrow.slick-prev {
    left: 5%;
  }
}
.paragraph-item-show.paragraph-type-carousel .slick-arrow.slick-next {
  right: 20%;
}
.paragraph-item-show.paragraph-type-carousel .slick-arrow.slick-next::before {
  content: "\f105";
}
@media screen and (max-width: 480px) {
  .paragraph-item-show.paragraph-type-carousel .slick-arrow.slick-next {
    right: 5vw;
  }
}
@media screen and (min-width: 1440px) {
  .node-type-projects .paragraph-item-show.paragraph-type-carousel .slick-arrow.slick-next {
    right: 21%;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1439px) {
  .node-type-projects .paragraph-item-show.paragraph-type-carousel .slick-arrow.slick-next {
    right: 15vw;
  }
}
@media screen and (max-width: 1199px) {
  .node-type-projects .paragraph-item-show.paragraph-type-carousel .slick-arrow.slick-next {
    right: 5%;
  }
}
.paragraph-item-show.paragraph-type-carousel .item {
  outline: none;
}
.paragraph-item-show.paragraph-type-carousel .item img {
  width: 100%;
  transform-origin: center;
}
.paragraph-item-show.paragraph-type-carousel .item.slick-slide img {
  transform: scale(0.9) translate3d(0px, 0px, 0px);
  transition: all 300ms ease;
  height: 440px;
}
.paragraph-item-show.paragraph-type-carousel .item.slick-center img {
  transform: scale(1) translate3d(0px, 0px, 0px);
  transition: all 300ms ease;
  box-shadow: 0px 1px 5px 2px #969696;
  height: 500px;
}
.paragraph-item-show.paragraph-type-carousel .item.slick-slide .item__watermark {
  left: 50px;
  bottom: 20px;
  transition: all ease 300ms ease;
}
.paragraph-item-show.paragraph-type-carousel .item.slick-center .item__watermark {
  left: 10px;
  bottom: 10px;
  transition: all ease 300ms ease;
}
@media screen and (max-width: 1199px) {
  .paragraph-item-show.paragraph-type-carousel .item {
    padding: 0 5px;
  }
  .paragraph-item-show.paragraph-type-carousel .item.slick-slide img, .paragraph-item-show.paragraph-type-carousel .item.slick-center img {
    height: auto;
    transform: scale(1);
  }
  .paragraph-item-show.paragraph-type-carousel .item.slick-slide .item__watermark, .paragraph-item-show.paragraph-type-carousel .item.slick-center .item__watermark {
    left: 10px;
    bottom: 10px;
  }
}
@media screen and (max-width: 767px) {
  .paragraph-item-show.paragraph-type-carousel .item {
    padding: 0 5px;
  }
  .paragraph-item-show.paragraph-type-carousel .item.slick-slide img {
    transform: scale(1);
    min-height: 165px;
    max-height: 242px;
    object-fit: contain;
  }
}
.paragraph-item-show.paragraph-type-embed .embed--content iframe {
  width: 100%;
  height: 450px;
}
.paragraph-item-show.paragraph-type-image img {
  object-fit: cover;
}
.paragraph-item-show.paragraph-type-image img, .paragraph-item-show.paragraph-type-image .magnific-link {
  border: 1px solid #ccc;
}

.responsive-tabs.nav-tabs {
  position: relative;
  z-index: 10;
  overflow: visible;
  border-bottom: none;
}
@media (min-width: 1200px) {
  .responsive-tabs.nav-tabs {
    border-bottom: 1px solid #ddd;
  }
}
.responsive-tabs.nav-tabs span.fa {
  position: absolute;
  top: 20px;
  right: 22px;
}
.responsive-tabs.nav-tabs span.fa.fa-chevron-up {
  display: none;
}
@media (min-width: 1200px) {
  .responsive-tabs.nav-tabs span.fa {
    display: none;
  }
}
.responsive-tabs.nav-tabs > li {
  display: none;
  float: none;
  text-align: center;
}
@media (max-width: 1199px) {
  .responsive-tabs.nav-tabs > li {
    width: 100% !important;
  }
}
.responsive-tabs.nav-tabs > li:last-of-type > a {
  margin-right: 0;
}
.responsive-tabs.nav-tabs > li > a {
  margin-right: 0;
  background: #fff;
  border: 1px solid #DDDDDD;
}
@media (min-width: 1200px) {
  .responsive-tabs.nav-tabs > li > a {
    margin-right: 4px;
  }
}
.responsive-tabs.nav-tabs > li.active {
  display: block;
}
.responsive-tabs.nav-tabs > li.active a {
  border: 1px solid #DDDDDD;
  border-radius: 2px;
}
@media (min-width: 1200px) {
  .responsive-tabs.nav-tabs > li.active a {
    border-bottom-color: transparent;
  }
}
@media (min-width: 1200px) {
  .responsive-tabs.nav-tabs > li {
    display: block;
    float: left;
  }
}
.responsive-tabs.nav-tabs.open span.fa.fa-chevron-up {
  display: block;
}
@media (min-width: 1200px) {
  .responsive-tabs.nav-tabs.open span.fa.fa-chevron-up {
    display: none;
  }
}
.responsive-tabs.nav-tabs.open span.fa.fa-chevron-down {
  display: none;
}
.responsive-tabs.nav-tabs.open > li {
  display: block;
}
.responsive-tabs.nav-tabs.open > li a {
  border-radius: 0;
}
.responsive-tabs.nav-tabs.open > li:first-of-type a {
  border-radius: 2px 2px 0 0;
}
.responsive-tabs.nav-tabs.open > li:last-of-type a {
  border-radius: 0 0 2px 2px;
}

.link-cover {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 3;
  opacity: 0;
}

.to-load:empty {
  display: none !important;
}

.form-control {
  border: 1px solid #ccc;
  min-height: 40px;
  border-radius: 0;
  padding: 10px 15px;
  box-shadow: none;
}

.nicescroll-rails {
  z-index: 333 !important;
}

.select2-container .select2-selection--single,
.multiselect__content-wrapper .select2-selection--single {
  display: flex;
  align-items: center;
}
.select2-container .select2-selection--single .select2-selection__arrow,
.multiselect__content-wrapper .select2-selection--single .select2-selection__arrow {
  top: auto;
}
.select2-container .nicescroll-rails,
.multiselect__content-wrapper .nicescroll-rails {
  margin-left: -5px;
  width: 3px !important;
}
.select2-container .nicescroll-cursors,
.multiselect__content-wrapper .nicescroll-cursors {
  width: 10px !important;
  height: 20px !important;
  margin-right: -4px;
}

.multiselect__content-wrapper {
  overflow: hidden !important;
}

.btn-default {
  padding: 10px 15px;
  text-align: center;
  min-width: 100px;
  transition: all ease 300ms;
}
.btn-default:hover, .btn-default:focus, .btn-default:active {
  color: #ffffff;
  background-color: #071d35;
  transition: all ease 300ms;
}

.link-btn {
  padding: 15px 50px;
  border: 1px solid #184188;
  text-align: center;
  color: #184188;
  display: inline-block;
}
.link-btn:hover {
  background-color: #184188;
  color: #ffffff;
}
@media (max-width: 767px) {
  .link-btn {
    min-width: 50%;
    max-width: 100%;
  }
}

.link-btn-blue {
  padding: 15px 50px;
  border: 1px solid #184188;
  text-align: center;
  color: #ffffff;
  display: inline-block;
  background-color: #184188;
}

.link-btn-white {
  padding: 15px 50px;
  border: 2px solid #ffffff;
  text-align: center;
  color: #ffffff;
  display: inline-block;
}
.link-btn-white:focus, .link-btn-white:hover {
  color: #ffffff;
}

.page-header {
  border: 0;
}

.divider {
  height: 1px;
  width: 100%;
  margin: 20px 0 30px;
  border: 0;
}

.m-sprites::before {
  background-image: url("/img/filinvest/social-links.svg");
  background-size: 100%;
  background-repeat: no-repeat;
}

.isMobile .wow {
  visibility: visible !important;
  -webkit-animation: none !important;
  animation: none !important;
}

.list-properties > div > .row {
  display: flex;
  flex-wrap: wrap;
}
.list-properties .item--wrapper {
  height: 100%;
}
.list-properties .item__title {
  margin: 0 0 10px;
}

@media (min-width: 992px) {
  .has-three .item {
    width: 33.33%;
  }
}

.prestige-transparent {
  background-color: #9e662b;
}

.futura-transparent {
  background-color: #ed1f29;
}

.aspire-transparent {
  background-color: #0f3866;
}

.bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
  width: 100%;
}
.bootstrap-select .dropdown-menu {
  border: 1px solid #bcceed;
  border-radius: 0;
  max-height: 200px;
}
.bootstrap-select .dropdown-menu > li > a:hover,
.bootstrap-select .dropdown-menu > li > a:focus {
  background-color: #bcceed;
  color: #184188;
  transition: all ease 300ms;
}
.bootstrap-select .dropdown-menu > li.active > a, .bootstrap-select .dropdown-menu > li.active > a:hover, .bootstrap-select .dropdown-menu > li.active > a:focus {
  background-color: #184188;
  color: #fff;
}
.bootstrap-select .dropdown-menu > li.disabled > a, .bootstrap-select .dropdown-menu > li.disabled > a:hover, .bootstrap-select .dropdown-menu > li.disabled > a:focus {
  background-color: #fff;
  color: #9c9c9c;
}
.bootstrap-select .bs-caret {
  position: absolute;
  right: 15px;
  top: 0;
}
.bootstrap-select .bs-caret .caret {
  border: 0;
  margin: 0;
  position: relative;
  top: 0;
}
.bootstrap-select .bs-caret .caret::after {
  content: "\f107";
  font-family: Fontawesome;
}

.item__watermark {
  position: absolute;
  bottom: 10px;
  left: 10px;
  font-size: 12px;
  color: #ffffff;
  z-index: 2;
}
.block--brands .item__watermark {
  left: auto;
  right: 10px;
}

.multiselect--disabled .multiselect__select {
  background: transparent;
}

.alert-notifications {
  position: fixed;
  top: 7em;
  right: 15px;
  min-width: 15%;
}

.last-word > span {
  font-weight: 700;
}

.ls-1 {
  letter-spacing: 1px;
}

.ls-2 {
  letter-spacing: 2px;
}

.zsiq_floatmain,
.zsiq_custommain {
  z-index: 333 !important;
}
.zsiq_floatmain .zsiq_theme11 .zsiq_off .siqico-chat:before,
.zsiq_floatmain .zsiq_theme11 .zsiq_off .siqico-chat div,
.zsiq_floatmain *,
.zsiq_custommain .zsiq_theme11 .zsiq_off .siqico-chat:before,
.zsiq_custommain .zsiq_theme11 .zsiq_off .siqico-chat div,
.zsiq_custommain * {
  color: #353434 !important;
}
.zsiq_floatmain.zsiq_floatmain.zsiq_theme11, .zsiq_floatmain.zsiq_custommain.zsiq_theme11,
.zsiq_custommain.zsiq_floatmain.zsiq_theme11,
.zsiq_custommain.zsiq_custommain.zsiq_theme11 {
  bottom: 15%;
  z-index: 333 !important;
}

.multiselect__tag-icon:hover {
  background-color: #003288 !important;
}

@media only screen and (min-width: 600px) {
  .spaces-commertial-padding {
    padding: 0px 115px 20px;
  }

  .space-commercial-image {
    padding: 0px 100px 40px;
  }
}
@media only screen and (max-width: 600px) {
  /*  .spaces-commertial-padding{
      padding: 0px 115px 20px;
    }
  */
}
.user-menu {
  background-color: #184188;
  padding: 0 30px;
}
@media (min-width: 992px) {
  .user-menu {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .user-menu .navbar-header {
    flex: 1;
  }
}
.user-menu #menu-item-12 .dropdown-menu {
  min-width: 100%;
}
.user-menu .navbar-header {
  text-align: center;
}
.user-menu a[href^="#"] {
  cursor: default;
}
@media (max-width: 1199px) {
  .user-menu {
    padding: 0;
  }
  .user-menu .navbar-collapse nav {
    display: flex;
  }
}
@media (max-width: 1366px) {
  .user-menu {
    flex-direction: column;
  }
}
@media (max-width: 991px) {
  .user-menu .navbar-header {
    width: 100%;
  }
  .user-menu .navbar-collapse {
    clear: both;
    background-color: #193461;
  }
  .user-menu .navbar-collapse nav {
    justify-content: center;
  }
}
@media (max-width: 767px) {
  .user-menu {
    padding: 0;
  }
  .user-menu .search-toggle {
    display: none;
  }
  .user-menu .navbar-collapse {
    padding: 0;
    background-color: transparent;
  }
  .user-menu .navbar-collapse nav {
    display: block;
  }
  .user-menu .search-header {
    padding: 0 15px;
  }
}
.user-menu .logo {
  display: inline-block;
  max-width: 200px;
}
.user-menu .navbar-toggle .icon-bar {
  background: #ffffff;
}
.user-menu .search-toggle, .user-menu .search-toggle:focus, .user-menu .search-toggle:hover {
  color: #ffffff;
}
.user-menu::before, .user-menu::after {
  display: none;
}
.user-menu a {
  color: #ffffff;
}
.user-menu .dropdown-toggle {
  display: flex;
  align-items: center;
}
.user-menu .dropdown-toggle i {
  font-weight: 700;
  transition: all ease 300ms;
  margin-top: 3px;
}
.user-menu .open .dropdown-toggle i {
  transform: rotate(180deg);
  transition: all ease 300ms;
}
.user-menu .open .dropdown-menu {
  visibility: visible;
  opacity: 1;
  transition: all ease 300ms;
}
@media (max-width: 767px) {
  .user-menu .open .dropdown-menu {
    display: block;
    transition: none;
    position: relative;
    transition: none;
  }
}
.user-menu .dropdown-menu {
  background-color: #ffffff;
  box-shadow: none;
  border-radius: 0;
  font-weight: 300;
  padding: 0;
  display: block;
  opacity: 0;
  visibility: hidden;
  transition: all ease 300ms;
  left: 0 !important;
  border: 1px solid #BCCEED;
}
@media (min-width: 992px) {
  .user-menu .dropdown-menu {
    left: 10px;
    width: max-content;
  }
}
@media (max-width: 767px) {
  .user-menu .dropdown-menu {
    position: relative;
    transition: none;
    display: none;
    visibility: visible;
    opacity: 1;
    background-color: #ffffff !important;
  }
}
.user-menu .dropdown-menu > li > a {
  color: #184188;
  border-bottom: 1px solid #BCCEED;
  padding: 15px;
  transition: all ease 300ms;
  font-weight: 300;
  white-space: normal;
}
.user-menu .dropdown-menu > li > a:hover, .user-menu .dropdown-menu > li > a:focus {
  background-color: #ddd !important;
  transition: all ease 300ms;
  color: #184188 !important;
}
.user-menu .dropdown-menu > li.active a, .user-menu .dropdown-menu > li.active a:hover, .user-menu .dropdown-menu > li.active a:focus {
  background-color: #ddd !important;
  transition: all ease 300ms;
  color: #184188 !important;
}
.user-menu .dropdown-menu > li:last-of-type > a {
  border-bottom: 0;
}
@media (min-width: 1200px) {
  .user-menu .navbar-nav {
    height: 100%;
  }
}
@media (max-width: 767px) {
  .user-menu .navbar-nav {
    margin: 0;
  }
}
.user-menu .navbar-nav > li, .user-menu .navbar-nav > li > a {
  height: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
@media (max-width: 767px) {
  .user-menu .navbar-nav > li, .user-menu .navbar-nav > li > a {
    display: block;
  }
}
.user-menu .navbar-nav .open > a, .user-menu .navbar-nav .open > a:hover, .user-menu .navbar-nav .open > a:focus {
  background-color: #305FAF;
  transition: all ease 300ms;
}
.user-menu .navbar-nav > li > a {
  padding: 20px 15px;
  font-weight: 300;
  transition: all ease 300ms;
}
.open .user-menu .navbar-nav > li > a, .open .user-menu .navbar-nav > li > a:hover, .open .user-menu .navbar-nav > li > a:focus, .user-menu .navbar-nav > li > a:hover, .user-menu .navbar-nav > li > a:focus {
  background-color: #305FAF;
  transition: all ease 300ms;
}
@media (max-width: 991px) {
  .user-menu .navbar-nav > li > a {
    padding: 20px 6px;
  }
}
.user-menu .navbar-nav > li.active > a, .user-menu .navbar-nav > li.active > a:hover, .user-menu .navbar-nav > li.active > a:focus {
  background-color: #193461;
  transition: all ease 300ms;
}

.search-header {
  position: absolute;
  left: 0;
  z-index: 333;
  width: 100%;
  background-color: rgba(24, 65, 136, 0.85);
  border-top: 1px solid #89B0F3;
  top: 100%;
}
@media (max-width: 767px) {
  .search-header {
    top: 0;
    position: relative;
    border: 0;
    display: block !important;
    height: auto !important;
  }
}
.search-header > .container {
  padding: 30px;
  display: flex;
  align-items: center;
  position: relative;
  min-height: 300px;
  -webkit-box-align: center;
}
@media (max-width: 767px) {
  .search-header > .container {
    min-height: auto;
    padding: 0 0 30px;
  }
}
.search-header .close-btn {
  position: absolute;
  top: 15px;
  right: 45px;
  background-color: transparent;
  border: 0;
  color: #ffffff;
  font-size: 30px;
  z-index: 1;
}
@media (max-width: 767px) {
  .search-header .close-btn {
    display: none;
  }
}
.search-header .btn {
  background-color: transparent;
  color: #ffffff;
  font-size: 40px;
  padding: 0;
}
@media (max-width: 767px) {
  .search-header .btn {
    font-size: 30px;
    right: 0;
  }
}
.search-header input {
  flex: 1;
  background-color: transparent;
  border-bottom: solid #ffffff;
  border-width: 0 0 2px 0;
  font-size: 35px;
  color: #ffffff;
  height: auto;
  font-weight: 700;
  padding-left: 0;
}
@media (max-width: 320px) {
  .search-header input {
    font-size: 20px;
    padding: 15px 30px 15px 0;
  }
}
@media (max-width: 767px) {
  .search-header input {
    font-size: 20px;
  }
}
@media (min-width: 768px) {
  .search-header input {
    padding: 15px 50px 15px 0;
  }
}
.search-header input:hover, .search-header input:focus {
  box-shadow: none;
}
.search-header input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #ffffff;
  opacity: 1;
}
.search-header input::-moz-placeholder {
  /* Firefox 19+ */
  color: #ffffff;
  opacity: 1;
}
.search-header input:-ms-input-placeholder {
  /* IE 10+ */
  color: #ffffff;
  opacity: 1;
}
.search-header input:-moz-placeholder {
  /* Firefox 18- */
  color: #ffffff;
  opacity: 1;
}

.front .block--banner .item--container {
  bottom: 55vh;
  top: inherit;
}
.front .block--banner .flex-control-nav {
  bottom: 4vh;
  z-index: 3;
}
.front .block--banner .flex-direction-nav {
  display: none;
}
.front .block--banner .flex-control-paging a {
  border: 1px solid #fff;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  background: transparent;
}
.front .block--banner .flex-control-paging a.flex-active {
  background: #ffffff;
}
.front .block--banner .item--content {
  display: block;
}
.block--banner .item--container {
  position: absolute;
  top: 40vh;
  left: 0;
  right: 0;
  z-index: 3;
}
.block--banner .item--content {
  display: flex;
  justify-content: center;
}
.block--banner .slides {
  height: 100%;
}
.block--banner .item {
  height: 100%;
  align-items: center;
  justify-content: center;
}
.block--banner .item img {
  height: 100% !important;
  width: 100%;
  object-fit: cover;
}
.block--banner .carousel-control {
  font-size: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.block--banner .carousel-indicators {
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50vh;
  bottom: inherit;
}
.block--banner .carousel-indicators > li {
  margin: 0 7.5px;
  width: 15px;
  height: 15px;
  border-radius: 100%;
}
.block--banner .block--search {
  z-index: 2;
}
.block--banner .block--search select {
  cursor: pointer;
}
.block--banner .block--search .form-control[disabled], .block--banner .block--search .form-control[readonly], .block--banner .block--search .multiselect--disabled {
  cursor: not-allowed;
}
.block--banner .block--search .multiselect--disabled .multiselect__current, .block--banner .block--search .multiselect--disabled .multiselect__select, .block--banner .block--search .multiselect__option--disabled {
  background: transparent;
  cursor: not-allowed;
}
.block--banner .block--search .advance-option:hover {
  color: #AAA !important;
}
.not-front .block--banner .carousel {
  position: relative;
}
.not-front .block--banner .carousel::before {
  content: "";
  background-color: #000;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  opacity: 0.2;
  display: block;
  z-index: 1;
}
.not-front .block--banner .item {
  height: auto;
}
.not-front .block--banner .item img {
  position: relative;
}
.not-front .block--banner .item--container {
  top: 50%;
}
@media (max-width: 1199px) {
  .not-front .block--banner .item--container {
    top: 40%;
  }
}
.not-front .block--banner .carousel-indicators {
  top: 60%;
}
@media (max-width: 1199px) {
  .not-front .block--banner .carousel-indicators {
    top: 70%;
  }
}
.not-front .block--banner .block--search {
  top: 70%;
}
@media (max-width: 991px) {
  .not-front .block--banner .block--search {
    top: 65%;
  }
}

.block--brands .item .item__image img {
  transform: scale(1);
  transition: all ease 1s;
}
.block--brands .item:hover .item__image img {
  transform: scale(1.1);
  transition: all ease 1s;
}
@media (max-width: 991px) {
  .block--brands .item {
    border: 3px solid #ffffff;
  }
}
.block--brands .item:nth-child(1) {
  flex: 1;
}
.block--brands .item:nth-child(1) .item__logo {
  background-color: #9E662B;
}
.block--brands .item:nth-child(2) .item__logo {
  background-color: #0F3866;
}
.block--brands .item:nth-child(2) .item__image {
  margin: 0 -1px;
}
.block--brands .item:nth-child(3) .item__logo {
  background-color: #e02826;
}
.block--brands .item:first-of-type .item--wrapper {
  border-width: 3px 1.5px 3px 0;
}
.block--brands .item:last-of-type .item--wrapper {
  border-width: 3px 0 3px 1.5px;
}
.block--brands .item .item--wrapper {
  border-width: 3px 1.5px;
  border-color: #184188;
  border-style: solid;
}
@media (max-width: 991px) {
  .block--brands .item .item--wrapper {
    border: 0;
  }
}
.block--brands .item__logo {
  position: absolute;
  bottom: 0;
  left: 0;
  max-width: 200px;
}
@media (max-width: 767px) {
  .block--brands .item__logo {
    max-width: 150px;
  }
}

.block--category {
  background-color: #ececec;
  padding: 2.5em 0;
}
@media (max-width: 767px) {
  .block--category {
    padding: 0;
    background-color: #ffffff;
  }
}
@media (max-width: 767px) {
  .block--category .block__title {
    padding: 15px 15px 0;
    text-align: center;
  }
}
@media (max-width: 767px) {
  .block--category > .container {
    padding: 0;
  }
}
@media (max-width: 479px) {
  .block--category .item {
    width: 100%;
  }
}
.block--category .item--wrapper {
  padding: 1.3px;
}
.block--category .item--wrapper .item__image img {
  transform: scale(1);
  transition: all ease 1s;
}
.block--category .item--wrapper:hover .item__image img {
  transform: scale(1.1);
  transition: all ease 1s;
}
.block--category .item--wrapper .item__title {
  z-index: 3;
}
.block--category .item--wrapper .item__watermark {
  font-size: 10px;
}
.block--category .item--wrapper .item--details {
  position: absolute;
  bottom: 0;
  display: flex;
  flex-flow: column;
}
.block--category .item--wrapper .item--details a {
  background-color: rgba(29, 61, 113, 0.5);
}
.block--category .item--wrapper .item--details a:hover, .block--category .item--wrapper .item--details a:focus {
  color: #ffffff;
}

.sold-project .overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
}

.page-offices .block--contact textarea {
  min-height: auto;
  max-height: 40px;
}
@media (max-width: 991px) {
  .block--contact > .container {
    padding: 0;
  }
}
.block--contact textarea {
  min-height: 90px;
  min-width: 100%;
  resize: none;
}
.block--contact .form-head > div {
  flex-grow: 1;
}
@media (max-width: 991px) {
  .block--contact .form-head, .block--contact .form-footer {
    flex-wrap: wrap;
    margin: 0;
  }
  .block--contact .form-head > div, .block--contact .form-footer > div {
    width: 50%;
    padding: 0;
  }
  .block--contact .form-head > div.webform-component-textarea, .block--contact .form-head > div.webform-select, .block--contact .form-footer > div.webform-component-textarea, .block--contact .form-footer > div.webform-select {
    width: 100%;
  }
  .block--contact .form-head .form-actions, .block--contact .form-footer .form-actions {
    display: flex;
    align-items: center;
    margin-top: 15px;
    justify-content: center;
  }
}
@media (max-width: 991px) and (max-width: 479px) {
  .block--contact .form-head .form-actions, .block--contact .form-footer .form-actions {
    flex-wrap: wrap;
  }
}
@media (max-width: 991px) {
  .block--contact .form-head .form-actions .captcha, .block--contact .form-footer .form-actions .captcha {
    transform: scale(1);
    margin-right: 30px;
  }
}
@media (max-width: 991px) and (max-width: 479px) {
  .block--contact .form-head .form-actions .captcha, .block--contact .form-footer .form-actions .captcha {
    margin: 0;
    display: flex;
    width: 100%;
  }
  .block--contact .form-head .form-actions .captcha .g-recaptcha, .block--contact .form-footer .form-actions .captcha .g-recaptcha {
    transform: scale(0.7);
    transform-origin: center;
    max-width: 300px;
    margin: 0 auto;
  }
}
.block--leasing {
  margin-bottom: 60px;
}
.block--leasing textarea {
  height: auto;
  resize: none;
}
.block--leasing .form-control, .block--leasing .webform-submit {
  height: 45px;
}
.block--leasing .captcha .g-recaptcha {
  transform: scale(0.8);
  transform-origin: 0 0;
  margin-top: -5px;
}
@media (max-width: 1199px) {
  .block--leasing form .webform-component {
    padding: 0;
  }
  .block--leasing form .webform-component.webform-component-textarea .form-control {
    height: auto;
    min-height: 130px;
  }
  .block--leasing form .captcha {
    text-align: center;
  }
  .block--leasing form .captcha .g-recaptcha {
    max-width: 300px;
    margin: 0 auto;
    transform: scale(1);
  }
  .block--leasing form .form-actions {
    text-align: center;
    margin: 15px -15px 0;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
  }
  .block--leasing form .form-actions .form-submit {
    margin-left: 15px;
  }
}
@media (max-width: 767px) {
  .block--leasing form .webform-component {
    padding: 0;
    width: 50%;
  }
  .block--leasing form .webform-component.webform-component-textarea .form-control {
    height: 45px;
    min-height: auto;
  }
}

.block--news {
  padding: 30px 15px;
}
.block--news > .row {
  display: flex;
  flex-wrap: wrap;
}
.block--news .title {
  margin: 5px 0 25px;
}
.block--news .headlines {
  border-right: 1px solid #ccc;
}
.block--news .headlines ul {
  padding: 0;
  list-style: none;
}
.block--news .headlines li {
  line-height: 1.7;
  margin-bottom: 10px;
}
.block--news .headlines li::before {
  content: "";
  border-radius: 100%;
  width: 5px;
  height: 5px;
  display: inline-block;
  background: #184188;
  margin-right: 10px;
  vertical-align: middle;
}
.block--news .headlines li:hover > a {
  color: #888888;
}
.block--news .corporate {
  flex: 1;
}
.block--news .corporate .item:nth-child(1) {
  flex: 1;
}
@media (max-width: 479px) {
  .block--news .corporate .item {
    width: 100%;
  }
}
@media (max-width: 991px) {
  .block--news .corporate .item:first-of-type {
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .block--news .corporate .item {
    padding: 0;
  }
}
.block--news .corporate .item .row {
  margin: 0;
}
.block--news .corporate .item .item__image img {
  transform: scale(1);
  transition: all ease 1s;
}
.block--news .corporate .item:hover .item__image img {
  transform: scale(1.1);
  transition: all ease 1s;
}
.block--news .corporate .item > .row, .block--news .corporate .item .item__image > a, .block--news .corporate .item .item--wrapper {
  height: 100%;
}
@media (max-width: 767px) {
  .block--news .corporate .item .item--wrapper {
    padding: 0 3px;
  }
}
@media (max-width: 479px) {
  .block--news .corporate .item .item--details {
    width: 100%;
  }
}
.block--news .corporate .item .item__image {
  padding: 0;
}
@media (max-width: 479px) {
  .block--news .corporate .item .item__image {
    width: 100%;
  }
}
.block--news .corporate .item .item__image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.block--news .corporate .item .item__title {
  margin: 10px 0;
  line-height: 1.4;
}
.block--news .corporate .item .link-cover:hover .item__title > a {
  color: #888;
}
@media (max-width: 767px) {
  .block--news .corporate {
    padding-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .block--news {
    padding: 0 15px;
  }
  .block--news .title {
    margin: 20px 0 25px;
  }
  .block--news .headlines {
    border: 0;
  }
  .block--news .headlines ul {
    flex-wrap: wrap;
    display: flex;
  }
  .block--news .headlines ul li {
    width: 50%;
    padding: 0 15px;
  }
}
@media (max-width: 767px) and (max-width: 479px) {
  .block--news .headlines ul li {
    width: 100%;
  }
}

.article-title, .article-description {
  line-height: 25px;
}

.article-title {
  margin: 0px 0px 10px;
}

.block--awards {
  padding: 0 15px 20px;
}
.block--awards .content--left, .block--awards .content--right {
  margin-bottom: 15px;
}
@media (max-width: 479px) {
  .block--awards .content--left, .block--awards .content--right {
    width: 100%;
  }
}
@media screen and (max-width: 479px) {
  .block--awards .block-title {
    padding-bottom: 15px;
  }
  .block--awards .row {
    display: flex;
    flex-direction: column-reverse;
  }
  .block--awards .item__title {
    margin: 0px;
    padding-bottom: 10px;
  }
}
.block--awards ul > li:hover > a {
  color: #888888;
}
.block--awards .item__title:hover a {
  color: #888;
}

@media (max-width: 767px) {
  .content-extra {
    flex-flow: column;
    margin-top: 20px;
  }
}

.block--footer__info .row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -40px;
}
.block--footer__info .row > div {
  display: flex;
  flex-flow: column;
  line-height: 25px;
  border-right: 2px solid #365D94;
  padding: 0 25px;
}
@media (max-width: 991px) {
  .block--footer__info .row > div {
    padding: 0 30px 30px;
    border-style: solid;
    border-width: 0 0 2px;
    border-color: #365D94;
  }
}
.block--footer__info .row > div .item__title {
  margin: 15px 0 20px;
}
.block--footer__info .row > div:last-of-type {
  border: 0;
}
.block--footer__info .row .newsletter {
  flex: 1;
}
.block--footer__info .row .newsletter form {
  display: flex;
  align-items: flex-end;
}
.block--footer__info .row .newsletter form > div {
  flex-grow: 1;
}
.block--footer__info .row .newsletter form .form-type-text, .block--footer__info .row .newsletter form .form-type-text input {
  width: 100%;
}
.block--footer__info .row .newsletter form .has-error input:focus {
  border-bottom: 2px solid #ff3838 !important;
  outline: none;
}
.block--footer__info .row .newsletter form input {
  background-color: transparent;
  border-bottom: solid #fff;
  border-width: 0 0 2px 0;
  padding: 2px;
  box-shadow: none;
}
.block--footer__info .row .newsletter form input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #ffffff;
  opacity: 1;
  font-weight: 700;
}
.block--footer__info .row .newsletter form input::-moz-placeholder {
  /* Firefox 19+ */
  color: #ffffff;
  opacity: 1;
}
.block--footer__info .row .newsletter form input:-ms-input-placeholder {
  /* IE 10+ */
  color: #ffffff;
  opacity: 1;
}
.block--footer__info .row .newsletter form input:-moz-placeholder {
  /* Firefox 18- */
  color: #ffffff;
  opacity: 1;
}
.block--footer__info .row .newsletter, .block--footer__info .row .careers {
  width: 30%;
}
@media (max-width: 991px) {
  .block--footer__info .row .newsletter, .block--footer__info .row .careers {
    width: 100%;
  }
}
.block--footer__info .row .blog, .block--footer__info .row .guide {
  width: 20%;
}
@media (max-width: 991px) {
  .block--footer__info .row .blog, .block--footer__info .row .guide {
    width: 100%;
  }
}
.block--footer__info .item__rm {
  flex-grow: 1;
  align-items: flex-end;
  display: flex;
  justify-content: center;
}
.block--footer__info .btn-default {
  background-color: #134781;
}

.career--block .block--title {
  font-size: 22px;
  color: #184188;
  padding: 0 15px;
}
.career--block .item__image-wrapper {
  display: flex;
  align-items: center;
  padding-bottom: 30px;
}
.career--block .item__image-wrapper img {
  width: 70px;
}
.career--block .item__content-wrapper {
  font-size: 16px;
  color: #555;
  line-height: 1.5;
}

.news-slider {
  padding-bottom: 20px;
}
.news-slider .carousel--content {
  font-size: 16px;
  position: absolute;
  bottom: 0px;
  padding: 20px 30px;
  color: #fff;
  left: 0;
  right: 0;
  background-color: rgba(24, 65, 136, 0.91);
  min-height: 15vh;
}
.news-slider .item {
  height: 50vh;
}
.news-slider .item img {
  margin: 0;
}
.news-slider .carousel-control {
  bottom: 55%;
  width: 3%;
  top: initial;
}
.news-slider .carousel-control > span {
  font-size: 20px;
}
.news-slider .nav__slider img {
  height: 120px;
  object-fit: cover;
}

.popup-ad .popup-wrapper {
  background: rgb(190, 217, 238);
}
.popup-ad .popup-content {
  color: #373737;
}
.popup-ad .hotline__link {
  padding: 10px 25px;
  text-align: center;
  background-color: #184188;
  color: #fff;
  border-radius: 7px;
  cursor: pointer;
}
.popup-ad .block__footer {
  background-color: #184188;
  padding: 20px;
}
.popup-ad .block__footer img {
  max-width: 35%;
}
.popup-ad .block__rm a {
  background-color: #184188;
  color: #fff;
}

@media screen and (min-width: 992px) {
  .page-categories .block--banner {
    overflow: hidden;
  }
}
@media (max-width: 479px) {
  .page-categories .title {
    font-size: 20px;
  }
}
.page-categories .page-header {
  font-weight: 700;
}
@media (max-width: 767px) {
  .page-categories .page-header {
    font-size: 30px;
  }
}
@media (max-width: 479px) {
  .page-categories .page-header {
    font-size: 20px;
    white-space: nowrap;
  }
}
@media (max-width: 767px) {
  .page-categories .description {
    padding: 0;
    margin-top: 0;
  }
}
.page-categories .list-projects .item .item__image img {
  transform: scale(1);
  transition: all ease 1s;
}
.page-categories .list-projects .item:hover .item__image img {
  transform: scale(1.1);
  transition: all ease 1s;
}
@media (max-width: 479px) {
  .page-categories .list-projects .item {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .page-categories .list-projects .item {
    padding: 1.3px;
    margin: 0;
  }
}
.page-categories .list-projects .item--details {
  padding: 20px 10px 40px;
}
.page-categories .list-projects > .container > .row {
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 1199px) {
  .page-categories .block--contact .container {
    width: 100%;
  }
}
.page-categories .block--contact .captcha {
  transform: scale(0.7);
  transform-origin: 0 0;
  display: block;
}
.page-categories .block--contact .form-textarea {
  height: 100%;
}
.page-categories .block--contact .form-textarea textarea {
  min-height: 100%;
}
.page-categories .block--contact .form-footer > div {
  flex-grow: 1;
}
@media (min-width: 992px) {
  .page-categories .block--contact .form-footer > div:nth-child(1) {
    width: 61%;
  }
}
@media (min-width: 992px) {
  .page-categories .block--contact .form-footer > div:nth-child(2) {
    width: 10%;
  }
}

.page-townscapes .block--inspired {
  border-right: 1px solid #ddd;
}
@media (max-width: 767px) {
  .page-townscapes .block--inspired {
    border: 0;
  }
  .page-townscapes .block--inspired .block-title {
    text-align: center;
    margin: 30px 0 50px;
  }
  .page-townscapes .block--inspired .item__rm {
    text-align: center;
  }
  .page-townscapes .block--inspired .content--wrapper {
    display: flex;
    flex-wrap: wrap;
  }
}
.page-townscapes .block--inspired .item__rm a {
  display: inline-block;
  line-height: 100%;
}
.page-townscapes .list-projects .item-flex-wrapper .item:nth-child(1) {
  flex: 1;
}
@media (max-width: 767px) {
  .page-townscapes .block--newsletter {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #ccc;
  }
  .page-townscapes .block--newsletter > form {
    max-width: 400px;
    margin: 0 auto;
  }
}
.page-townscapes .block--newsletter .block-title {
  margin-top: 100px;
}
@media (max-width: 767px) {
  .page-townscapes .block--newsletter .block-title {
    margin-top: 0;
  }
}
.page-townscapes .block--newsletter input {
  border: 1px solid #184188;
}
@media (max-width: 767px) {
  .page-townscapes .block--newsletter .item__rm {
    text-align: center;
  }
}

.page-mall .list-projects .item-flex-wrapper .item:nth-child(1) {
  flex: 1;
}

.page-residential .group-item {
  flex-wrap: wrap;
}
@media (max-width: 479px) {
  .page-residential .group-item {
    margin-bottom: 0;
  }
}
@media (max-width: 767px) {
  .page-residential .group-item > div.list-projects {
    padding: 0 15px !important;
  }
}
@media (max-width: 479px) {
  .page-residential .group-item > div.brand {
    padding: 3px 6px;
  }
  .page-residential .group-item > div.list-projects {
    padding: 0 !important;
  }
  .page-residential .group-item > div.list-projects .item {
    padding: 3px 6px;
  }
}
.page-residential .group-item .brand {
  flex: 1;
}
.page-residential .group-item .brand .item--wrapper {
  padding: 30px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  border: 2px solid #ececec;
  height: 100%;
}
.page-residential .group-item .list-projects .item .item__image img {
  transform: scale(1);
  transition: all ease 1s;
}
.page-residential .group-item .list-projects .item:hover .item__image img {
  transform: scale(1.1);
  transition: all ease 1s;
}
.page-residential .group-item .list-projects .item--content {
  padding: 15px 20px;
}
@media (min-width: 992px) {
  .page-residential .group-item .list-projects .item--content {
    min-height: 120px;
  }
}
@media (max-width: 767px) {
  .page-residential .footer-info {
    padding: 0 6px;
  }
}
.page-residential .block--inspired {
  border-right: 1px solid #ddd;
}
@media (max-width: 767px) {
  .page-residential .block--inspired {
    border: 0;
  }
  .page-residential .block--inspired .block-title {
    text-align: center;
    margin: 30px 0 50px;
  }
  .page-residential .block--inspired .item__rm {
    text-align: center;
  }
  .page-residential .block--inspired .content--wrapper {
    display: flex;
    flex-wrap: wrap;
  }
}
.page-residential .block--inspired .item__rm a {
  display: inline-block;
  line-height: 100%;
}
@media (max-width: 767px) {
  .page-residential .block--newsletter {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #ccc;
  }
  .page-residential .block--newsletter > form {
    max-width: 400px;
    margin: 0 auto;
  }
}
.page-residential .block--newsletter .block-title {
  margin-top: 100px;
}
@media (max-width: 767px) {
  .page-residential .block--newsletter .block-title {
    margin-top: 0;
  }
}
.page-residential .block--newsletter input {
  border: 1px solid #184188;
}
@media (max-width: 767px) {
  .page-residential .block--newsletter .item__rm {
    text-align: center;
  }
}
.page-residential .list-unstyled > li {
  word-break: break-all;
}

.page-leisure .list-projects .item-flex-wrapper .item:nth-child(1) {
  flex: 1;
}
.page-search .page-header {
  margin: 20px auto 0;
}
@media (max-width: 767px) {
  .page-search .results-title {
    color: #184188;
    font-size: 25px;
  }
}
.page-search .block--search .container {
  padding: 20px 15px;
  width: 100%;
  border-bottom: 3px solid #184188;
  position: relative;
}
@media (max-width: 767px) {
  .page-search .block--search .container {
    padding: 0;
  }
}
.page-search .block--search .main-search {
  max-width: 1170px;
  width: 100%;
  margin: 0 auto;
}
.page-search .block--search .main-search .multiselect__tags {
  border-radius: 0;
  border: 1px solid #D5DEEC;
}
.page-search .block--search .main-search .form-group__wrapper {
  width: 100%;
}
@media (max-width: 767px) {
  .page-search .block--search .main-search .form-group__wrapper {
    padding: 0;
  }
}
.page-search .block--search .main-search .form-group__wrapper > div {
  display: flex;
}
@media (max-width: 767px) {
  .page-search .block--search .main-search .form-group__wrapper > div {
    margin: 0;
  }
}
@media (min-width: 768px) {
  .page-search .block--search .main-search .form-group__wrapper .row-5 > div {
    flex-grow: 1;
    width: 33.33%;
  }
}
@media (max-width: 767px) {
  .page-search .block--search .main-search .form-group__wrapper .row-5 > div {
    padding: 0;
  }
}
@media (max-width: 767px) {
  .page-search .block--search .main-search .form-group__wrapper .row-5 > div.field-component-select {
    width: 100%;
  }
}
.page-search .block--search .main-search #advance-search {
  width: 100%;
}
@media (max-width: 767px) {
  .page-search .block--search .main-search #advance-search {
    padding: 0;
  }
}
.page-search .block--search .main-search .advance-search {
  margin-top: 20px;
  padding: 0 15px;
  margin: 20px -20px 0;
}
@media (max-width: 767px) {
  .page-search .block--search .main-search .advance-search {
    padding: 0;
    margin: 0;
  }
}
.page-search .block--search .main-search .advance-search > div {
  width: 33.33%;
}
@media (max-width: 767px) {
  .page-search .block--search .main-search .advance-search > div {
    padding: 0;
  }
}
@media (max-width: 479px) {
  .page-search .block--search .main-search .advance-search > div {
    width: 100%;
  }
}
.page-search .block--search .row {
  margin-left: auto;
  margin-right: auto;
  float: none;
  max-width: 1200px;
}
.page-search .block--search .row:last-of-type {
  margin-bottom: 0;
}
.page-search .block--search select, .page-search .block--search .form-control {
  border: 1px solid #D5DEEC;
  padding: 10px;
  border-radius: 0;
}
.page-search .block--search .search-action-area {
  position: absolute;
  display: flex;
  margin: 0 auto;
  left: 0;
  right: 0;
  bottom: -51px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  height: 50px;
  width: 100%;
}
@media (max-width: 767px) {
  .page-search .block--search .search-action-area {
    justify-content: flex-end;
  }
}
.page-search .block--search .search-action-area a {
  font-size: 0;
  border-radius: 100%;
  top: -18px;
  display: flex;
  position: relative;
  height: 75px;
  justify-content: center;
  align-items: center;
  width: 80px;
  background-color: #184188;
  color: #ffffff;
}
.page-search .block--search .search-action-area a.advance-option i {
  transition: all ease 500ms;
  transform: rotate(180deg);
}
.page-search .block--search .search-action-area a.collapsed i {
  bottom: -10px;
  transition: all ease 500ms;
  transform: rotate(0);
}
.page-search .block--search .search-action-area i {
  font-size: 30px;
  position: relative;
  bottom: -15px;
}
.page-search .block--search .search-action-area i::before {
  content: "\f078";
}
.page-search .filter-tabs {
  border: solid #89B0F3;
  border-width: 1px 0;
  margin: 20px 0 30px;
}
.page-search .filter-tabs .nav-tabs {
  padding: 0 13.5px;
}
@media (max-width: 767px) {
  .page-search .filter-tabs .nav-tabs {
    padding: 0;
  }
}
.page-search .filter-tabs .nav-tabs > li {
  margin: 0;
  display: flex;
  align-items: center;
  padding: 0 1.5px;
  -webkit-clip-path: polygon(15% 0%, 85% 0%, 100% 100%, 0% 100%);
  clip-path: polygon(15% 0%, 85% 0%, 100% 100%, 0% 100%);
  margin-left: -50px;
  transition: all ease 300ms;
}
.page-search .filter-tabs .nav-tabs > li:nth-child(1) {
  margin-left: 0px;
  z-index: 3;
}
.page-search .filter-tabs .nav-tabs > li:nth-child(2) {
  z-index: 2;
}
@media (max-width: 767px) {
  .page-search .filter-tabs .nav-tabs > li {
    width: 33.33%;
    padding: 0;
    -webkit-clip-path: polygon(10% 0%, 90% 0%, 100% 100%, 0% 100%);
    clip-path: polygon(10% 0%, 90% 0%, 100% 100%, 0% 100%);
    margin-left: -15px;
  }
}
.page-search .filter-tabs .nav-tabs > li img {
  max-width: 150px;
  width: 100%;
}
@media (max-width: 767px) {
  .page-search .filter-tabs .nav-tabs > li img {
    max-width: 100%;
    width: auto;
  }
}
.page-search .filter-tabs .nav-tabs > li.active, .page-search .filter-tabs .nav-tabs > li:hover, .page-search .filter-tabs .nav-tabs > li:focus {
  opacity: 1;
  transition: all ease 300ms;
}
.page-search .filter-tabs .nav-tabs > li.active .prestige, .page-search .filter-tabs .nav-tabs > li:hover .prestige, .page-search .filter-tabs .nav-tabs > li:focus .prestige {
  background-color: rgb(158, 102, 43);
}
.page-search .filter-tabs .nav-tabs > li.active .aspire, .page-search .filter-tabs .nav-tabs > li:hover .aspire, .page-search .filter-tabs .nav-tabs > li:focus .aspire {
  background-color: rgb(25, 65, 135);
}
.page-search .filter-tabs .nav-tabs > li.active .futura, .page-search .filter-tabs .nav-tabs > li:hover .futura, .page-search .filter-tabs .nav-tabs > li:focus .futura {
  background-color: rgb(224, 40, 38);
}
.page-search .filter-tabs .nav-tabs a {
  border: 0;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 5px 50px;
}
@media (max-width: 767px) {
  .page-search .filter-tabs .nav-tabs a {
    width: 100%;
    padding: 5px 15px;
    justify-content: center;
  }
}
.page-search .filter-tabs .nav-tabs a.prestige {
  background-color: #C09874;
}
.page-search .filter-tabs .nav-tabs a.aspire {
  background-color: #7592AE;
}
.page-search .filter-tabs .nav-tabs a.futura {
  background-color: #BE9292;
}
.page-search .filter-results {
  padding: 0 15px;
  margin: 0 auto 20px;
}
.page-search .filter-results .item {
  padding: 5px 15px;
  display: inline-flex;
  align-items: center;
  vertical-align: top;
  border: 1px solid #184188;
  transition: all ease 300ms;
  border-radius: 10px;
  margin-bottom: 5px;
}
.page-search .filter-results .item i {
  cursor: pointer;
  background-color: #184188;
  padding: 5px 7px;
  margin-left: 30px;
  color: #fff;
  border-radius: 100%;
  justify-content: center;
  display: inline-flex;
  align-items: center;
  font-size: 12px;
}
.page-search .item .item__image img {
  transform: scale(1);
  transition: all ease 1s;
}
.page-search .item:hover .item__image img {
  transform: scale(1.1);
  transition: all ease 1s;
}
@media (max-width: 767px) {
  .page-search .item {
    padding: 1.3px;
    margin: 0;
  }
}
.page-search .item .item--details {
  font-weight: 300;
}
@media (max-width: 991px) {
  .page-search .item .item--details {
    min-height: 150px;
  }
}
.page-search .item .item--details .field-location {
  margin-top: 2px;
}
@media (max-width: 767px) {
  .page-search .pager--show__more {
    margin-top: 20px;
  }
}
@media (max-width: 1199px) {
  .page-search .group-item {
    margin-bottom: 30px;
  }
  .page-search .group-item:last-of-type {
    margin-bottom: 0;
  }
}
@media (max-width: 767px) {
  .page-search .group-item .slick-slider {
    padding-bottom: 100px;
  }
}
.page-search .group-item .slick-slider .slick-track {
  float: left;
}
.page-search .group-item .slick-slider .slick-arrow {
  position: absolute;
  z-index: 1;
  font-size: 0;
  border: 0;
  background-color: transparent;
  padding: 0;
  top: 30%;
}
.page-search .group-item .slick-slider .slick-arrow::before {
  font-family: "Fontawesome";
  font-size: 70px;
  color: #184188;
  text-shadow: 0 0 3px #ffffff;
}
.page-search .group-item .slick-slider .slick-arrow.slick-prev {
  left: -50px;
}
.page-search .group-item .slick-slider .slick-arrow.slick-prev::before {
  content: "\f104";
}
.page-search .group-item .slick-slider .slick-arrow.slick-next {
  right: -50px;
}
.page-search .group-item .slick-slider .slick-arrow.slick-next::before {
  content: "\f105";
}
.page-search .group-item .slick-slider .slick-arrow.slick-disabled {
  opacity: 0.6;
}
@media (max-width: 1199px) {
  .page-search .group-item .slick-slider .slick-arrow {
    top: -90px;
  }
  .page-search .group-item .slick-slider .slick-arrow.slick-prev {
    right: 50px;
    left: auto;
  }
  .page-search .group-item .slick-slider .slick-arrow.slick-next {
    right: 10px;
  }
}
@media (max-width: 767px) {
  .page-search .group-item .slick-slider .slick-arrow {
    top: 20%;
  }
  .page-search .group-item .slick-slider .slick-arrow.slick-prev {
    left: 10px;
  }
  .page-search .group-item .slick-slider .slick-arrow.slick-next {
    right: 10px;
  }
}
.page-search .group-item .brand-image {
  top: 0;
  left: 0;
  width: 100%;
  max-width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  z-index: 1;
}
.page-search .group-item .brand-image img {
  width: 100%;
}
.page-search .group-item .item__image, .page-search .group-item .progressive-image {
  height: 265px;
  max-width: 100% !important;
}
.page-search .group-item .item__image img, .page-search .group-item .progressive-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.page-search .group-item.active {
  display: flex !important;
  flex-wrap: wrap;
}
.page-search .group-item.active .item .item--wrapper {
  height: 100%;
}

.page-offices .list-projects .item-flex-wrapper .item:nth-child(1) {
  flex: 1;
}
.page-offices .list-projects .item__title {
  word-break: break-word;
}
.page-offices .block--tabs .navigation-tabs {
  margin-bottom: 60px;
  border-style: solid;
  border-width: 1px 0;
  border-color: #e2e2e2;
}
.page-offices .block--tabs .navigation-tabs li.active a {
  background-color: #BDC9DF;
  color: #184188;
  border-style: solid !important;
  border-width: 0 0 1px 0 !important;
  border-color: #184188;
}
.page-offices .block--tabs .navigation-tabs li.active a::before, .page-offices .block--tabs .navigation-tabs li.active a::after {
  opacity: 1 !important;
}
.page-offices .block--tabs .navigation-tabs li.active a::before {
  border-top: 16px solid #184188;
}
.page-offices .block--tabs .navigation-tabs li.active a::after {
  border-top: 15px solid #BDC9DF;
}
.page-offices .block--tabs .navigation-tabs a {
  color: #555;
  padding: 20px 50px;
  position: relative;
  min-width: 250px;
  text-align: center;
  border-style: solid;
  border-width: 0 0 1px 0;
  border-color: #e2e2e2;
  margin: 0;
}
.page-offices .block--tabs .navigation-tabs a::before {
  content: "";
  display: inline-block;
  width: 0px;
  height: 0px;
  border-left: 27px solid transparent;
  border-right: 27px solid transparent;
  border-top: 16px solid #e2e2e2;
  position: absolute;
  bottom: -16px;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1;
  opacity: 0;
}
.page-offices .block--tabs .navigation-tabs a::after {
  content: "";
  display: inline-block;
  width: 0px;
  height: 0px;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  border-top: 15px solid #eeeeee;
  position: absolute;
  bottom: -15px;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1;
  opacity: 0;
}
.page-offices .block--tabs .navigation-tabs a:hover::before, .page-offices .block--tabs .navigation-tabs a:hover::after, .page-offices .block--tabs .navigation-tabs a:focus::before, .page-offices .block--tabs .navigation-tabs a:focus::after {
  opacity: 1;
}
.page-offices .block--tabs .tab-lease .carousel-caption {
  padding-bottom: 15px;
  right: 20px;
  left: auto;
  bottom: 0;
}
.page-offices .block--tabs .tab-lease .item .project--name {
  color: rgba(255, 255, 255, 0.7);
}
.page-offices .block--tabs .tab-lease .item .item--content .border-right {
  border-right: 1px solid #ccc;
}
.page-offices .block--tabs .tab-lease .type-carousel .carousel-control {
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: none;
  opacity: 0.7;
  transition: all ease 300ms;
}
.page-offices .block--tabs .tab-lease .type-carousel .carousel-control:hover {
  opacity: 1;
  transition: all ease 300ms;
}
.page-offices .block--tabs .tab-lease .type-carousel .carousel-control.left {
  justify-content: flex-start;
}
.page-offices .block--tabs .tab-lease .type-carousel .carousel-control.right {
  justify-content: flex-end;
}
.page-offices .block--tabs .tab-lease .type-carousel .carousel-control .fa {
  padding: 15px;
  background-color: #184188;
  width: 50px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 70px;
  font-size: 30px;
}
.page-offices .block--tabs .tab-lease .type-gallery .item__images {
  display: flex;
  margin: 0 -1.5px;
  flex-wrap: wrap;
}
.page-offices .block--tabs .tab-lease .type-gallery .item__image {
  padding: 0 1.5px;
}
.page-offices .block--tabs .tab-lease .type-gallery .item--content {
  margin-top: 3px;
}
@media (max-width: 767px) {
  .page-offices .block--tabs .navigation-tabs {
    margin-bottom: 10px;
  }
  .page-offices .block--tabs .navigation-tabs .nav-tabs {
    padding: 0;
  }
  .page-offices .block--tabs .navigation-tabs li {
    width: 50%;
  }
  .page-offices .block--tabs .navigation-tabs a {
    padding: 15px;
    min-width: auto;
  }
  .page-offices .block--tabs .navigation-tabs a::before, .page-offices .block--tabs .navigation-tabs a::after {
    display: none;
  }
  .page-offices .tab-content {
    padding: 0;
  }
  .page-offices .tab-content .tab-sale > .row {
    margin: 0;
  }
  .page-offices .tab-content .tab-lease {
    padding: 0 6px;
  }
  .page-offices .tab-content .tab-lease .item__title {
    text-align: left;
  }
  .page-offices .tab-content .tab-lease .type-gallery .item__image {
    margin-bottom: 6px;
  }
}
.page-offices .global-form {
  padding: 0;
}

.page-office-parks .content {
  border: 1px solid #ECECEC;
}
@media (max-width: 1199px) {
  .page-office-parks .content > div:first-of-type {
    padding: 15px;
  }
}
.page-office-parks .global-form {
  padding: 0;
}

.page-corporate .global-form, .page-offices .global-form {
  padding: 0;
}

@media (max-width: 991px) {
  .page-articles .main-content {
    padding: 0;
  }
}
.page-articles .main-content .item .item__image img {
  transform: scale(1);
  transition: all ease 1s;
}
.page-articles .main-content .item:hover .item__image img {
  transform: scale(1.1);
  transition: all ease 1s;
}
.page-articles .main-content .item:hover .item--wrapper {
  background-color: #EBFCFF;
  transition: all ease 300ms;
}
.page-articles .main-content .item:hover .item__rm a {
  transition: all ease 300ms;
  background-color: #C4DFEE;
}
.page-articles .main-content .item--wrapper {
  height: 100%;
  display: flex;
  flex-flow: column;
  padding-bottom: 50px;
  transition: all ease 300ms;
}
.page-articles .main-content .item--wrapper .item--description {
  flex-grow: 1;
  overflow: hidden;
}
.page-articles .main-content .item--wrapper .item--description img {
  display: none;
}
.page-articles .main-content .item--wrapper .item--details {
  overflow: hidden;
}
.page-articles .main-content .item--wrapper .item__rm {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  right: 0;
}
.page-articles .main-content .item--wrapper .item__rm a {
  display: block;
  width: 100%;
  background-color: #D9D9D9;
  padding: 15px;
  text-align: center;
  transition: all ease 300ms;
}
.page-articles .main-content .item__image, .page-articles .main-content .progressive-image {
  height: 250px;
  max-width: 100% !important;
}
.page-articles .main-content .item__image img, .page-articles .main-content .progressive-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.sidebar-secondary .form-control {
  width: 100%;
}
.sidebar-secondary .fb-page {
  padding: 0 0 10px;
  background-color: #F4F5F6;
}
.sidebar-secondary .filter {
  margin-bottom: 35px;
  border: 1px solid #ccc;
}
.sidebar-secondary .filter i.btn {
  position: absolute;
  right: 0;
}
.sidebar-secondary .form-label {
  margin: 0 0 25px;
  font-weight: 400;
  text-transform: capitalize;
}
.sidebar-secondary .dropdown-toggle {
  border-radius: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  width: 100%;
  text-align: left;
  padding: 10px 5px;
  color: #184188;
}

.page-careers.modal-open {
  overflow: hidden;
}
@media (max-width: 991px) {
  .page-careers.has-sticky .main-wrapper {
    overflow: hidden;
  }
}
.page-careers .help-block.with-errors {
  word-break: break-word;
  font-size: 14px;
}
@media (max-width: 1199px) {
  .page-careers hr {
    display: none;
  }
}
@media (max-width: 1199px) {
  .page-careers .g-recaptcha {
    transform: scale(0.7);
    transform-origin: center;
  }
}
@media (max-width: 991px) {
  .page-careers .footer {
    margin-top: 0;
  }
}
.page-careers .block--banner::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}
.page-careers .block--banner img {
  height: 480px;
  object-fit: cover;
  width: 100%;
}
@media (max-width: 991px) {
  .page-careers .block--banner img {
    height: 300px;
  }
}
.page-careers .block--banner .page-header {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
@media (max-width: 991px) {
  .page-careers .block--career-content > .row {
    margin: 0;
    padding: 0 50px;
  }
}
@media (max-width: 479px) {
  .page-careers .block--career-content > .row {
    margin: 0;
    padding: 0 20px;
  }
  .page-careers .block--career-content > .row .item {
    margin-bottom: 30px;
  }
  .page-careers .block--career-content > .row .item__title {
    text-align: center;
  }
  .page-careers .block--career-content > .row .item__image {
    max-width: 100px;
    margin: 0 auto 15px;
  }
}
.page-careers .block--career-content .item__title:hover a {
  color: #888;
}
@media (max-width: 1199px) {
  .page-careers .block--open-positions .block-title {
    display: none;
  }
}
.page-careers .filter--tabs {
  border-bottom: 1px solid #e2e2e2;
}
@media (max-width: 1199px) {
  .page-careers .filter--tabs {
    max-width: 300px;
    border: 1px solid #333;
    margin: 0 auto 30px;
  }
}
.page-careers .filter--tabs .nav-tabs {
  border: 0;
}
.page-careers .filter--tabs .nav-tabs li a {
  margin: 0;
  padding: 15px 25px;
  border-style: solid;
  border-width: 0 1px 0 0;
  border-color: #ccc;
}
.page-careers .filter--tabs .nav-tabs li:first-of-type a {
  border-width: 0 1px 0;
}
@media (max-width: 1199px) {
  .page-careers .filter--tabs .nav-tabs {
    margin: 0 auto;
    color: #184188;
    padding: 0;
    width: 100%;
  }
  .page-careers .filter--tabs .nav-tabs li.active a {
    color: #184188;
    height: auto !important;
  }
  .page-careers .filter--tabs .nav-tabs li a {
    border: 0;
    height: auto !important;
  }
}
@media (max-width: 1199px) {
  .page-careers .block--content > .row15- {
    margin: 0 auto;
  }
}
@media (max-width: 991px) {
  .page-careers .block--content > .row15- {
    flex-direction: column-reverse;
  }
}
.page-careers .block--content > .row15- > * {
  padding: 0 7.5px;
}
@media (min-width: 1200px) {
  .page-careers .block--content > .row15- > *.sidebar-primary {
    width: 35%;
  }
  .page-careers .block--content > .row15- > *.content {
    width: 65%;
  }
}
@media (max-width: 991px) {
  .page-careers .panel-group {
    margin: 0;
  }
}
.page-careers .item-position {
  margin-bottom: 10px;
}
.page-careers .item-position .panel-heading {
  padding: 0;
}
.page-careers .item-position .accordion-toggle {
  padding: 20px 30px;
  background-color: #ececec;
  display: flex;
  flex-flow: column;
  justify-content: center;
}
.page-careers .item-position .accordion-toggle.collapsed i {
  transform: rotate(180deg);
  transition: all ease 300ms;
}
.page-careers .item-position .accordion-toggle i {
  position: absolute;
  right: 30px;
  transition: all ease 300ms;
}
@media (max-width: 479px) {
  .page-careers .item-position .accordion-toggle i {
    top: 28px;
    right: 10px;
  }
}
.page-careers .item-position .btn-default {
  padding: 15px;
  background-color: #184188;
  color: #ffffff;
  display: inline-block;
  border-radius: 0;
  margin: 20px 0 0;
  transition: all ease 300ms;
}
.page-careers .item-position .btn-default:hover, .page-careers .item-position .btn-default:focus {
  color: #ffffff;
  background-color: #071d35;
  transition: all ease 300ms;
}
@media (min-width: 320px) {
  .page-careers .modal-dialog .modal-dialog {
    position: relative;
    width: 80%;
    margin: 10px auto;
  }
}
@media (min-width: 768px) {
  .page-careers .modal-dialog {
    width: 600px;
  }
}
@media (min-width: 1200px) {
  .page-careers .modal-dialog {
    width: 50%;
  }
}
.page-careers .modal-content {
  border-radius: 0;
}
.page-careers .modal-content .modal-header .close {
  background: #ccc;
  border-radius: 100%;
  display: flex;
  margin: 0;
  text-align: center;
  opacity: 1;
  justify-content: center;
  color: #fff;
  width: 30px;
  height: 31px;
  position: absolute;
  top: 15px;
  right: 15px;
}
.page-careers .modal-content .modal-header .close span {
  margin-top: -2px;
}
.page-careers .modal-content .modal-title {
  text-align: center;
}
@media (max-width: 479px) {
  .page-careers .modal-content .modal-title {
    text-align: left;
    font-size: 14px;
  }
}
.page-careers .modal-content .modal-body {
  background-color: #f5f5f5;
}
.page-careers .modal-content .form-required {
  color: red;
}
.page-careers .modal-content .form-control {
  border: 2px solid #eee;
}
.page-careers .modal-content .file-upload {
  background-color: transparent;
  border: 1px solid #ccc !important;
  box-shadow: none;
  cursor: pointer;
}
.page-careers .file-upload {
  border: 1px solid #ccc;
  height: auto;
  padding: 30px;
}
.page-careers .webform-submit {
  margin-left: 15px;
}
.page-careers .link-btn-white:hover {
  background-color: #071d35;
}
@media screen and (max-width: 1199px) and (min-width: 992px) {
  .page-careers .field-contact br {
    content: "";
    height: 5px;
    display: block;
  }
  .page-careers .sticky > div {
    padding: 15px 30px;
    font-size: 16px;
  }
  .page-careers .sticky .link-btn-white {
    margin-top: 10px;
  }
}

.thankyou-page .page-title {
  color: #184188;
}
.thankyou-page .details-wrapper {
  font-size: 25px;
  color: #555;
  line-height: 32px;
}
.thankyou-page .btn__back {
  padding: 10px 15px;
  font-size: 16px;
  text-align: center;
  min-width: 100px;
  -webkit-transition: all ease 300ms;
  transition: all ease 300ms;
  border-radius: 0;
}
.thankyou-page .content-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}
.thankyou-page .btn-default {
  background-color: #134781;
  transition: all ease 300ms;
  color: #fff;
  border-radius: 0px;
}
.thankyou-page .btn-default:hover {
  color: #ffffff;
  background-color: #071D35;
  transition: all ease 300ms;
}
@media (max-width: 767px) {
  .thankyou-page .content-wrapper {
    padding-bottom: 50px;
    min-height: 70vh;
  }
  .thankyou-page .page-title {
    padding: 30px 0;
  }
}
@media (max-width: 767px) {
  .thankyou-page .page-title {
    font-size: 45px;
  }
}

.page-mixed-use .list-projects .item-flex-wrapper .item:nth-child(1) {
  flex: 1;
}

.node-type-projects .nav__slider.less-slick .slick-track {
  transform: none !important;
}
.node-type-projects .bgc-grey {
  background-color: #e2e2e2;
}
.node-type-projects .container {
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
.node-type-projects .field--subdivisions .field-label {
  margin-right: 20px;
}
.node-type-projects .field--subdivisions .dropdown-toggle {
  padding: 10px 20px;
  border: 1px solid #e1e1e1;
  color: #555;
  min-width: 245px;
  display: block;
}
.node-type-projects .field--subdivisions .dropdown-item {
  padding: 5px 20px;
}
.node-type-projects .field--subdivisions .dropdown-menu {
  width: 100%;
}
@media screen and (max-width: 767px) {
  .node-type-projects .field--subdivisions {
    flex-direction: column;
  }
  .node-type-projects .field--subdivisions .field-label {
    margin-right: 0px;
    padding-bottom: 10px;
  }
}
.node-type-projects .field--media-links .field-items {
  margin-left: 20px;
}
.node-type-projects .field--media-links a {
  color: #184188;
  font-size: 20px;
  border-radius: 100%;
  border: 1px solid;
  width: 40px;
  height: 40px;
  justify-content: center;
  display: inline-flex;
  padding: 5px;
  align-items: center;
  margin: 0 5px;
}
@media screen and (max-width: 767px) {
  .node-type-projects .field--media-links {
    flex-direction: column;
  }
}
@media screen and (max-width: 767px) {
  .node-type-projects .image-text-block {
    flex-direction: column;
  }
  .node-type-projects .image-text-block .image-content {
    padding-bottom: 10px;
  }
}
.node-type-projects .block--banner .item img {
  max-height: 750px !important;
}
.node-type-projects .basic--details {
  border-bottom: 1px solid #ECECEC;
}
.node-type-projects .basic--details .logo {
  display: flex;
  align-items: flex-end;
}
.node-type-projects .basic--details .logo img {
  max-height: 130px;
}
.node-type-projects .basic--details .has-padding {
  padding: 35px 50px;
  display: flex;
  align-items: flex-start;
  flex-flow: column;
}
@media screen and (max-width: 479px) {
  .node-type-projects .basic--details {
    padding-top: 20px;
  }
  .node-type-projects .basic--details .container {
    display: block;
  }
  .node-type-projects .basic--details .has-padding {
    padding: 10px 15px;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .node-type-projects .basic--details .has-padding {
    padding: 25px 15px;
  }
  .node-type-projects .basic--details .fs22pt {
    font-size: 22px;
  }
}
@media screen and (max-width: 767px) {
  .node-type-projects .basic--details .title {
    font-size: 24px;
  }
  .node-type-projects .basic--details .fs16pt {
    font-size: 13pt;
  }
  .node-type-projects .basic--details .fs22pt {
    font-size: 18pt;
  }
  .node-type-projects .basic--details .logo {
    justify-content: center;
  }
  .node-type-projects .basic--details .logo img {
    max-height: 100px;
  }
}
.node-type-projects .slider--wrapper .slick-slider {
  min-height: auto;
}
.node-type-projects .slider--wrapper .slick-arrow {
  position: absolute;
  top: 40%;
  padding: 15px;
  background-color: rgb(24, 65, 136);
  color: #fff;
  opacity: 0.45;
  z-index: 2;
}
.node-type-projects .slider--wrapper .slick-arrow:hover {
  opacity: 1;
}
.node-type-projects .slider--wrapper .slick-arrow.left {
  left: 0px;
}
.node-type-projects .slider--wrapper .slick-arrow.right {
  right: 0px;
}
@media screen and (max-width: 757px) {
  .node-type-projects .slider--wrapper .slick-arrow {
    padding: 10px !important;
    top: 33%;
  }
  .node-type-projects .slider--wrapper .slick-arrow::before {
    font-size: 36px;
  }
}
.node-type-projects .slider--wrapper .nav-item {
  padding: 0 5px;
  position: relative;
}
.node-type-projects .slider--wrapper .nav-item:before {
  content: "";
  position: absolute;
  background-color: #fff;
  opacity: 0.6;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: opacity 0.3s;
}
.node-type-projects .slider--wrapper .nav-item img {
  height: 100px;
  width: 100%;
  object-fit: cover;
}
@media screen and (max-width: 575px) {
  .node-type-projects .slider--wrapper .nav-item img {
    height: 60px;
  }
}
.node-type-projects .slider--wrapper .nav-item.slick-current:before {
  opacity: 0;
  transition: opacity 0.3s;
}
.node-type-projects .block-form {
  padding: 0 15px;
}
.node-type-projects .block-form .captcha {
  transform: scale(0.7);
  transform-origin: 0 0;
  display: block;
}
.node-type-projects .block-form .form-textarea {
  height: 100%;
}
.node-type-projects .block-form .form-textarea textarea {
  min-height: 100%;
}
.node-type-projects .block-form .form-footer > div {
  flex-grow: 1;
}
@media (min-width: 992px) {
  .node-type-projects .block-form .form-footer > div:nth-child(1) {
    width: 62%;
  }
}
@media (min-width: 992px) {
  .node-type-projects .block-form .form-footer > div:nth-child(2) {
    width: 10%;
  }
}
@media screen and (max-width: 767px) {
  .node-type-projects .block-form .form-head > div {
    width: 100%;
    padding: 5px 0;
  }
}
.node-type-projects .image--content img {
  border: 1px solid rgb(224, 224, 224);
  height: 350px;
  object-fit: contain;
  width: 100%;
}

@media (max-width: 767px) {
  .node-type-webform .to-load > hr {
    display: none;
  }
}
.node-type-webform address {
  line-height: 1.6;
}
.node-type-webform .page-header {
  margin: 35px 0;
}
@media (max-width: 991px) {
  .node-type-webform .main-container > .row {
    flex-direction: column-reverse;
  }
  .node-type-webform .main-container > .row > div:last-of-type {
    padding: 0;
  }
}
.node-type-webform .webform-component {
  margin-bottom: 20px;
}
.node-type-webform .webform-component.webform-component-checkbox {
  margin-bottom: 20px;
}
.node-type-webform .webform-component label, .node-type-webform .webform-component .form-control, .node-type-webform .webform-component select, .node-type-webform .webform-component textarea {
  color: #555;
}
.node-type-webform .main-container .form-control, .node-type-webform .main-container .form-control:hover, .node-type-webform .main-container .form-control:focus, .node-type-webform .main-container .bootstrap-select .dropdown-toggle, .node-type-webform .main-container .bootstrap-select .dropdown-toggle:hover, .node-type-webform .main-container .bootstrap-select .dropdown-toggle:focus {
  border-radius: 0;
  border: 1px solid #e1e1e1;
  background-color: #ffffff;
  color: #333;
}
.node-type-webform textarea {
  min-height: 85px;
  resize: none;
}
.node-type-webform .has-danger .form-control, .node-type-webform .has-danger .dropdown-toggle, .node-type-webform .has-danger input[type=checkbox] + label:before {
  border: 1px solid #f73636 !important;
}
.node-type-webform .help-block:empty {
  display: none;
}
.node-type-webform .help-block.with-errors {
  font-size: 12px;
  color: #f73636;
}
@media (max-width: 991px) {
  .node-type-webform .form-actions {
    margin-bottom: 30px;
  }
}
.node-type-webform input[type=checkbox] {
  display: none;
}
.node-type-webform input[type=checkbox] + label {
  font-weight: 400;
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.node-type-webform input[type=checkbox] + label:last-child {
  margin-bottom: 0;
}
.node-type-webform input[type=checkbox] + label:before {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  border: 1px solid #ccc;
  position: absolute;
  left: 0;
  top: 5px;
  -webkit-transition: all 0.12s, border-color 0.08s;
  transition: all 0.12s, border-color 0.08s;
}
.node-type-webform input[type=checkbox]:checked + label:before {
  width: 10px;
  top: -5px;
  left: 5px;
  border-radius: 0;
  opacity: 1;
  border-top-color: transparent;
  border-left-color: transparent;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.advertisement .text-title {
  color: #174398;
}
.advertisement hr {
  border-top: 1px solid #e2e2e2;
}
.advertisement .paragraph-block {
  line-height: 1.5;
}
.advertisement .block-form {
  padding: 0 15px;
}
.advertisement .block-form .captcha {
  transform: scale(0.7);
  transform-origin: 0 0;
  display: block;
}
.advertisement .block-form .form-textarea {
  height: 100%;
}
.advertisement .block-form .form-textarea textarea {
  min-height: 100%;
}
.advertisement .block-form .form-footer > div {
  flex-grow: 1;
}
@media (min-width: 992px) {
  .advertisement .block-form .form-footer > div:nth-child(1) {
    width: 62%;
  }
}
@media (min-width: 992px) {
  .advertisement .block-form .form-footer > div:nth-child(2) {
    width: 10%;
  }
}
@media screen and (max-width: 767px) {
  .advertisement .block-form .form-head > div {
    width: 100%;
    padding: 5px 0;
  }
}
.advertisement .paragraph-item-show.paragraph-type-carousel .slick-slider {
  min-height: auto;
}
.advertisement .paragraph-item-show.paragraph-type-carousel .item {
  outline: none;
  margin: 0 15px;
}
.advertisement .paragraph-item-show.paragraph-type-carousel .item img {
  width: 100%;
  transform-origin: center;
  height: 400px;
}
@media screen and (max-width: 767px) {
  .advertisement .paragraph-item-show.paragraph-type-carousel .item img {
    height: 200px;
    object-fit: cover;
  }
}
@media screen and (max-width: 1199px) and (min-width: 768px) {
  .advertisement .paragraph-item-show.paragraph-type-carousel .item img {
    min-height: 200px !important;
    height: auto;
  }
}
.advertisement .paragraph-item-show.paragraph-type-carousel .item.slick-slide img {
  transform: scale(1) translate3d(0px, 0px, 0px);
  transition: all 300ms ease;
}
.advertisement .paragraph-item-show.paragraph-type-carousel .item.slick-center img {
  transform: scale(1) translate3d(0px, 0px, 0px);
  transition: all 300ms ease;
  box-shadow: 0px 1px 5px 2px #969696;
}
.advertisement .paragraph-item-show.paragraph-type-carousel .slick-arrow {
  opacity: 1;
  background-color: #184188;
  padding: 5px 15px;
  top: 35%;
}
@media screen and (max-width: 767px) {
  .advertisement .paragraph-item-show.paragraph-type-carousel .slick-arrow {
    top: 25%;
  }
}
.advertisement .paragraph-item-show.paragraph-type-carousel .slick-arrow:before {
  line-height: normal;
}
.advertisement .paragraph-item-show.paragraph-type-carousel .slick-arrow.slick-next {
  right: 23.2%;
}
@media screen and (min-width: 320px) and (max-width: 479px) {
  .advertisement .paragraph-item-show.paragraph-type-carousel .slick-arrow.slick-next {
    top: 10vh;
    right: 5%;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .advertisement .paragraph-item-show.paragraph-type-carousel .slick-arrow.slick-next {
    top: 10vh;
    right: 2.5%;
  }
}
@media screen and (min-width: 768px) and (max-width: 1198px) {
  .advertisement .paragraph-item-show.paragraph-type-carousel .slick-arrow.slick-next {
    right: 35.5%;
    top: 10vh;
  }
}
@media screen and (width: 1199px) {
  .advertisement .paragraph-item-show.paragraph-type-carousel .slick-arrow.slick-next {
    right: 28.5%;
    top: 20%;
  }
}
.advertisement .paragraph-item-show.paragraph-type-carousel .slick-arrow.slick-prev {
  left: 23.2%;
}
@media screen and (min-width: 320px) and (max-width: 479px) {
  .advertisement .paragraph-item-show.paragraph-type-carousel .slick-arrow.slick-prev {
    top: 10vh;
    left: 5%;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .advertisement .paragraph-item-show.paragraph-type-carousel .slick-arrow.slick-prev {
    top: 10vh;
    left: 2.5%;
  }
}
@media screen and (min-width: 768px) and (max-width: 1198px) {
  .advertisement .paragraph-item-show.paragraph-type-carousel .slick-arrow.slick-prev {
    left: 35.5%;
    top: 10vh;
  }
}
@media screen and (width: 1199px) {
  .advertisement .paragraph-item-show.paragraph-type-carousel .slick-arrow.slick-prev {
    left: 28.5%;
    top: 20%;
  }
}
.advertisement .iltr .text-content {
  padding: 40px 20px 40px 40px;
  line-height: 27px;
}
.advertisement .irtl .text-content {
  padding: 40px 40px 40px 0;
  line-height: 27px;
}
@media screen and (max-width: 991px) {
  .advertisement .h-slider {
    display: block !important;
    padding: 0 15px !important;
  }
  .advertisement .h-slider .item {
    display: inline-block;
    float: left;
  }
  .advertisement .iltr .text-content {
    padding: 40px;
  }
  .advertisement .irtl .text-content {
    padding: 40px;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .advertisement .h-slider .item {
    width: 25% !important;
  }
}
@media screen and (max-width: 767px) {
  .advertisement .h-slider {
    padding: 0 15px;
  }
  .advertisement .h-slider .item {
    width: 100% !important;
  }
}
@media screen and (min-width: 1200px) {
  .advertisement .slick-slider .slick-slide .block--text {
    display: none;
  }
  .advertisement .slick-slider .slick-slide.slick-current .block--text {
    display: block;
  }
}

.footer .footer-top {
  background: #184188;
}
.footer .footer-credits > .container {
  padding: 15px;
}
.footer .footer-credits > .container > div {
  padding: 0;
}
@media (max-width: 767px) {
  .footer .footer-credits > .container > div {
    justify-content: center;
  }
  .footer .footer-credits > .container > div:first-of-type {
    margin-bottom: 15px;
  }
}