// Responsive
$min-xs: 320px;
$min-xxs: 480px;
$min-sm: 768px;
$min-md: 992px;
$min-lg: 1200px;

$max-lg: 1199px;
$max-md: 991px;
$max-sm: 767px;
$max-xxs: 479px;
// Colors
$white: #ffffff;
$grey: #ececec;
$lightgrey: #eeeeee;
$blue: #184188;
$dblue: #071d35;
$lblue: #96b7c4;
$darkgrey: #e2e2e2;
