.footer {
  .footer-top {
    background: #184188;
  }

  .footer-credits > .container {
    padding: 15px;
    > div {
      padding: 0;
      @media (max-width: $max-sm) {
        justify-content: center;
        &:first-of-type {
          margin-bottom: 15px;
        }
      }
    }
  }
}
