// Banner 
.block--banner {
	.front & {
		.item--container {
			bottom: 55vh;
    		top: inherit;
		} 
		.flex-control-nav {
			bottom: 4vh;
			z-index: 3;
		}
		.flex-direction-nav {
			display: none;
		}
		.flex-control-paging {
			li {

			} 
			a {
				border: 1px solid #fff;
				border-radius: 100%;
				width: 20px;
				height: 20px;
				background: transparent;
				&.flex-active {
					background: $white;
				}
			}
		}
		.item--content {
			display: block;
		}
	}
	.item--container {
		position: absolute;
		top: 40vh;
		left: 0;
		right: 0;
		z-index: 3;
	} 
	.item--content {
		display: flex; 
		justify-content: center; 
	}
	.slides {
		height: 100%;
	}
	.item {
		height: 100%;
		align-items: center;
		justify-content: center;
		img { 
			height: 100% !important;
			width: 100%;
			object-fit: cover;
		}
	} 
	.carousel-control {
		font-size: 60px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.carousel-indicators {
		display: flex;
		align-items: center;
		justify-content: center;
		top: 50vh;
		bottom: inherit;
		> li {
			margin: 0 7.5px;
			width: 15px;
			height: 15px;
			border-radius: 100%;
		}
	}
	.block--search {
		z-index: 2;
		select {
			cursor: pointer;
		}
		.form-control[disabled], .form-control[readonly], .multiselect--disabled {
			cursor: not-allowed;
			// @media (max-width: $max-lg) {
			// 	.isMobile & {
			// 		opacity: .6;
			// 	}
			// }
		} 
		.multiselect--disabled .multiselect__current, .multiselect--disabled .multiselect__select, .multiselect__option--disabled {
			background: transparent;
			cursor: not-allowed;
		}
		.advance-option:hover {
			color: #AAA !important;
		}
	} 
	.not-front & {
		.carousel {
			position: relative;
			&::before { 
				content: '';
				background-color: #000;
				position: absolute;
				width: 100%;
				height: 100%; 
				top: 0;
				opacity: .2;
				display: block;
				z-index: 1;
			}
		} 
		.item { 
			height: auto;
			img {
				position: relative;
			}
		}
		.item--container {
			top: 50%;
			@media (max-width: $max-lg) {
				top: 40%;
			}
		}
		.carousel-indicators {
			top: 60%;
			@media (max-width: $max-lg) {
				top: 70%;
			}
		}
		.block--search { 
			top: 70%;
			@media (max-width: $max-md) {
				top: 65%;
			}
		}
	}
}

// Brands 
.block--brands {
	.item {
		@include imageHover;
		@media(max-width: $max-md) {
			border: 3px solid $white;
		}
		&:nth-child(1) {
			flex: 1;
			.item__logo {
				background-color: #9E662B;
			}
		}
		&:nth-child(2) {
			.item__logo {
				background-color: #0F3866;
			}
			.item__image {
				margin: 0 -1px;
			}
		}
		&:nth-child(3) {
			.item__logo {
				background-color: #e02826;
			}
		}
		&:first-of-type .item--wrapper {
			border-width: 3px 1.5px 3px 0;
		}
		&:last-of-type .item--wrapper {
			border-width: 3px 0 3px 1.5px;
		} 
		.item--wrapper {
			border: {
				width: 3px 1.5px;
				color: $blue; 
				style: solid;
			}
			@media (max-width: $max-md) {
				border: 0;
			}
		} 
		
		
	}
	.item__logo {
		position: absolute;
		bottom: 0;
		left: 0;
		max-width: 200px;
		@media (max-width: $max-sm) {
			max-width: 150px;
		}
	}
}    
// Category
.block--category {
	background-color: $grey;
	padding: 2.5em 0;
	@media (max-width: $max-sm) {
		padding: 0;
		background-color: $white;
	}
	.block__title {
		@media (max-width: $max-sm) { 
			padding: 15px 15px 0;
			text-align: center;
		}
	}
	> .container {
		@media (max-width: $max-sm) {
			padding: 0;
		}
	}
	.item {
		@include xxsFull;
	}
	.item--wrapper {  
		padding: 1.3px;
		@include imageHover;
		.item__title {
			z-index: 3;
		}
		.item__watermark {
			font-size: 10px;
			// top: -15px;
			// bottom: auto;

		} 
		.item--details {
			position: absolute; 
			bottom: 0;
			display: flex;
			flex-flow: column;
			a {
				background-color: rgba(29, 61, 113, .5);
				&:hover, &:focus {
					color: $white;
				}
			}
		}
		
	}  
	
}

.sold-project{
	.overlay{
	    position: absolute;
	    top: 0;
	    width: 100%;
	    height: 100%;
	    background: rgba(255,255,255,.2);
	}
}

// Forms

// Start Contact Form
.block--contact {
	.page-offices & {
		textarea	 {
			min-height: auto;
			max-height: 40px;
		}


	}
	> .container {
		@media (max-width: $max-md) {
			padding: 0;
		}
	}
	textarea {
		min-height: 90px;
		min-width: 100%;
		resize: none;
	}
	.form-head {
		> div {
			flex-grow: 1;
		}
	} 
	.form-head, .form-footer {
		@media (max-width: $max-md) {
			flex-wrap: wrap;
			margin: 0;
			> div {
				width: 50%;
				padding: 0;
				&.webform-component-textarea, &.webform-select {
					width: 100%;
				}
			}
			.form-actions {
				display: flex;
				align-items: center;
				margin-top: 15px;
				justify-content: center;
				@media (max-width: $max-xxs) {
					flex-wrap: wrap;
				}
				.captcha {
					transform: scale(1);
					margin-right: 30px;
					@media (max-width: $max-xxs) {
						margin: 0;
						display: flex;
						width: 100%;
						.g-recaptcha {
							transform: scale(.7);
							transform-origin: center;
							max-width: 300px;
							margin: 0 auto;
						}
					}
				}
				.webform-submit {

				}
			}
		}
	}
} // End Contact Form

// Start Contact
.block--leasing {
	margin-bottom: 60px;
	textarea {
		height: auto;
		resize: none;
	}
	.form-control, .webform-submit {
		height: 45px;
	}
	.captcha .g-recaptcha {
		transform: scale(.8);
		transform-origin: 0 0;
		margin-top: -5px;
	}
	form { 
		// min-lg
		@media (min-width: $min-lg) {
			// > div { 
			// 	flex-grow: 1; 
			// 	width: 20%;
			// 	height: 45px;
			// 	&:last-of-type {
			// 		width: 10%;
			// 	}
			// }
		} // End min-lg
		// max-lg
		@media (max-width: $max-lg) {
			.webform-component {
				padding: 0;
				&.webform-component-textarea {
					.form-control {
						height: auto;
						min-height: 130px;
					}
				}
			}
			.captcha {
				text-align: center;
				.g-recaptcha {
					max-width: 300px;
					margin: 0 auto;
					transform: scale(1);
				}
			}
			.form-actions {
				text-align: center;
				margin: 15px -15px 0;
				align-items: center;
				justify-content: center;
				flex-wrap: wrap;
				width: 100%;
				.form-submit {
					margin-left: 15px;
				}
			}
		} // end max-lg
		// max-sm
		@media (max-width: $max-sm) {
			.webform-component {
				padding: 0;
				width: 50%;
				&.webform-component-textarea {
					.form-control {
						height: 45px;
						min-height: auto;
					}
				}
			}
		}
		// end-max-sm
	}
} // End Leasing Form

// End Forms

// Start News
.block--news {
	padding: 30px 15px;
	> .row {
		display: flex;
		flex-wrap: wrap;
	}
	.title {
		margin: 5px 0 25px;
		
	}
	.headlines {
		border-right: 1px solid #ccc;
		ul {
			padding: 0;
			list-style: none;
		}
		li {
			line-height: 1.7;
			margin-bottom: 10px;
			&::before {
				content: '';
				border-radius: 100%;
				width: 5px;
				height: 5px;
				display: inline-block;
				background: $blue;
				margin-right: 10px;
				vertical-align: middle;
			}

			&:hover {
				> a {
					color: #888888;
				}
			}
		}
	}
	.corporate {
		flex: 1;
		.item {
			&:nth-child(1) {
				flex: 1;
			}
			@include xxsFull;
			@media (max-width: $max-md) {
				&:first-of-type {
					margin-bottom: 15px;
				}
			}
			@media (max-width: $max-sm) {
				padding: 0;
			}
			.row {
				margin: 0;
			}
			@include imageHover;
			> .row, .item__image > a, .item--wrapper {
				height: 100%;
			}
			.item--wrapper {
				@media (max-width: $max-sm) {
					padding: 0 3px;
				}
			}
			.item--details {
				@include xxsFull;
			}
			.item__image {
				padding: 0;
				@include xxsFull;
				img {
					height: 100%;
					width: 100%;
					object-fit: cover;
				}
			}
			.item__title {
				margin: 10px 0;
				line-height: 1.4;
			}
			.link-cover {
				&:hover {
					.item__title {
						> a {
							color: #888;
						}
					}
				}
			}
		}

		@media (max-width: $max-sm) {
			padding-bottom: 15px;
		}
	}

	// Responsive SM
	@media (max-width: $max-sm) {
		& {
			padding: 0 15px;
		}
		.title {
			margin: 20px 0 25px;
	
		}
		.headlines {
			border: 0;
			ul {
				flex-wrap: wrap;
				display: flex;
				li {
					width: 50%;
					@include xxsFull;
					padding: 0 15px; 
				}
			}
		}
	}
}

.article-title, .article-description{
	line-height: 25px;
}

.article-title{
	margin: 0px 0px 10px;
}

// Awards
.block--awards {
	padding: 0 15px 20px;
	.content--left, .content--right {
		@include xxsFull;
		margin-bottom: 15px;
	}

	@media screen and (max-width: 479px) {
		.block-title {
			padding-bottom: 15px;
		}
		.row {
			display: flex;
			flex-direction: column-reverse;
		}

		.item__title {
			margin: 0px;
			padding-bottom: 10px;
		}
	}

	ul {
		> li {

			&:hover {
				> a {
					color: #888888;
				}
			}
		}
	}

	.item__title {
		&:hover {
			a {
				color: #888;
			}
		}
	}
}

// Content extra
.content-extra {
	@media (max-width: $max-sm) {
		flex-flow: column;
		margin-top: 20px;
	}
}
// Block Info
.block--footer__info {
	.row {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -40px;
		> div {
			display: flex;
			flex-flow: column;
			line-height: 25px;
			border-right: 2px solid #365D94;
			padding: 0 25px;
			@media (max-width: $max-md) {
				padding: 0 30px 30px;
				border: {
					style: solid;
					width: 0 0 2px;
					color: #365D94;
				}
			}
			.item__title {
				margin: 15px 0 20px;
			}
			&:last-of-type {
				border: 0;
			}
		}
		.newsletter {
			flex: 1;
			form { 
				display: flex;
				align-items: flex-end;
				> div {
					flex-grow: 1;
				}
				.form-type-text {
					
					&, input { 
						width: 100%;
					}
				} 
				.has-error input:focus {
					border-bottom: 2px solid #ff3838 !important;
					outline: none;
				}
				input {
					background-color: transparent;
					border-bottom: solid #fff;
					border-width: 0 0 2px 0;
					padding: 2px;
					box-shadow: none;
					&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
						color: $white;
						opacity: 1;
						font-weight: 700;
					}
					&::-moz-placeholder { /* Firefox 19+ */
						color: $white;
						opacity: 1;
					}
					&:-ms-input-placeholder { /* IE 10+ */
						color: $white;
						opacity: 1;
					}
					&:-moz-placeholder { /* Firefox 18- */
						color: $white;
						opacity: 1;
					}
				}
			}
		}

		.newsletter, .careers {
			width: 30%;
			@media (max-width: $max-md) {
				width: 100%;
			}
		}
		.blog, .guide {
			width: 20%;
			@media (max-width: $max-md) {
				width: 100%;
			}
		}
	}
	.item__rm {
		flex-grow: 1;
		align-items: flex-end;
		display: flex;
		justify-content: center;
		
	}
	.btn-default {
		background-color: #134781;
	}
}
 

// career
.career--block {
	.block--title {
		font-size: 22px;
		color: #184188;
		padding: 0 15px;
	}

	.item__image-wrapper {
		display: flex;
		align-items: center;
		padding-bottom: 30px;

		img {
			width: 70px;
		}
	}
	.item__content-wrapper {
		font-size: 16px;
		color: #555;
		line-height: 1.5;
	}
}

.news-slider {
	padding-bottom: 20px;
	.carousel--content {
			font-size: 16px;
			position: absolute;
			bottom: 0px;
			padding: 20px 30px;
			color: #fff;
			left: 0;
			right: 0;
			background-color: rgba(24, 65, 136, 0.91);
			min-height: 15vh;
	}

	.item {
			height: 50vh;

			img {
				margin: 0;
			}
	}

	.carousel-control {
			bottom: 55%;
			width: 3%;
			top: initial;

			> span {
					font-size: 20px;
			}
	}
	.nav__slider {
		img {
				height: 120px;
				object-fit: cover;
		}
	}
}

.popup-ad {
	.popup-wrapper {
		// background: rgb(190,217,238);
		// background: linear-gradient(30deg, rgba(190,217,238,1) 82%, rgba(255,255,255,1) 100%);
		background: rgb(190,217,238);
// background: linear-gradient(90deg, rgba(190,217,238,1) 97%, rgba(255,255,255,1) 100%);
	}
	.popup-content {
		color: #373737;
	}
	.hotline__link {
		padding: 10px 25px;
		text-align: center;
		background-color: $blue;
		color: #fff;
		border-radius: 7px;
		cursor: pointer;
	}

	.block__footer {
		background-color: $blue;
		padding: 20px;
		img {
			max-width: 35%;
		}
	}

	.block__rm a {
		background-color: #184188;
		color: #fff;
	}
}

// .cookies-wrapper {
// 	&.append {
// 		background-color: rgb(44, 81, 146);
// 	}
// }
