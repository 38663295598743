.user-menu {
	background-color: $blue;
	padding: 0 30px;
	@media (min-width: $min-md) {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		> div {

		}
		.navbar-header {
			flex: 1;
		}
	} 
	#menu-item-12 .dropdown-menu { 
		min-width: 100%;
	}

	.navbar-header {
		text-align: center;
	}
	a[href^="#"] { 
		cursor: default;
	} 
	@media (max-width: $max-lg) { 
		& {
			padding: 0; 
		} 
		.navbar-collapse {
			nav {
				display: flex; 
			} 
		} 
	}
	@media (max-width: 1366px) { 
		& {
			flex-direction: column;
		}
	}
	@media (max-width: $max-md) {
		.navbar-header {
			width: 100%;
		}
		.navbar-collapse { 
			clear: both; 
			background-color: #193461;
			nav {
				justify-content: center;
			}
		}
	}
	@media (max-width: $max-sm) {
		padding: 0;
		.search-toggle {
			display: none;
		}
		.navbar-collapse { 
			padding: 0;
			background-color: transparent;
			nav {
				display: block;
			}
		}
		.search-header {
			padding: 0 15px;
		}
	}
	.logo {  
		display: inline-block;
		max-width: 200px;
	}
	.navbar-toggle {
		.icon-bar {
			background: $white;
		}
	}
	.search-toggle {
		&, &:focus, &:hover {
			color: $white;
		}
	}
	
	&::before, &::after {
		display: none;
	}
	a {
		color: $white;
	}
	.dropdown-toggle {
		display: flex;
		align-items: center;
		i {
			font-weight: 700;  
			transition: all ease 300ms;
			margin-top: 3px; 
		}
	}
	.open {
		.dropdown-toggle {
			i {
				transform: rotate(180deg);
				transition: all ease 300ms;
			}  
		}
		.dropdown-menu {
			visibility: visible;
			opacity: 1;
			transition: all ease 300ms;
			
			@media (max-width: $max-sm) {
				display: block;
				transition: none; 
				position: relative; 
				transition: none;
			}			
		}  
	}
	
	.dropdown-menu {
		background-color: $white;
		box-shadow: none;
		border-radius: 0;
		font-weight: 300; 
		padding: 0;
		display: block;
		opacity: 0;
		visibility: hidden;
		transition: all ease 300ms;
		left: 0 !important;
		border: 1px solid #BCCEED;
		
		@media (min-width: $min-md) {
			left: 10px; 
			width: max-content;
		}
		@media (max-width: $max-sm) {
			position: relative;
			transition: none;
			display: none;
			visibility: visible;
			opacity: 1;
			background-color: $white !important;
		}
		> li {
			> a {
				color: #184188;
				border-bottom: 1px solid #BCCEED;
				padding: 15px;  
				transition: all ease 300ms;
				font-weight: 300;
				white-space: normal;
				&:hover, &:focus {
					background-color: #ddd !important;
					transition: all ease 300ms; 
					color: $blue !important;  
				} 
			}
			&.active a, &.active a:hover, &.active a:focus {
				background-color: #ddd !important;
				transition: all ease 300ms; 
				color: $blue !important;  
			}
			&:last-of-type > a {
				border-bottom: 0;
			}
		}
	} 
	.navbar-nav {
		
		@media (min-width: $min-lg) {
			height: 100%;
		}
		@media (max-width: $max-sm) {
			margin: 0;
		}
		> li, > li > a {
			height: 100%;
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			@media (max-width: $max-sm) {
				display: block;
			}
		}
		.open > a, .open > a:hover, .open > a:focus {
			background-color: #305FAF;
			transition: all ease 300ms;
		}
		> li {
			> a {
				padding: 20px 15px;
				font-weight: 300;
				transition: all ease 300ms;
				.open &, .open &:hover, .open &:focus, &:hover, &:focus {
					background-color: #305FAF;
					transition: all ease 300ms;
				}
				@media (max-width: $max-md) {
					padding: 20px 6px;
				}
				
			}
			&.active > a, &.active > a:hover, &.active > a:focus {
				background-color: #193461;
				transition: all ease 300ms;
			}
		}
	}
}  

.search-header {
	position: absolute;
    left: 0;
    z-index: 333;
	width: 100%;
	background-color: rgba(24, 65, 136, 0.85);
	border-top: 1px solid #89B0F3;
	top: 100%;
	@media (max-width: $max-sm) {
		top: 0;
		position: relative;
		border: 0;
		display: block !important;
		height: auto !important;
	}
	> .container {
		padding: 30px;
		display: flex;
		align-items: center;
		position: relative;
		min-height: 300px;
		-webkit-box-align: center;
		@media (max-width: 767px) {
			min-height: auto;
			padding: 0 0 30px;
		}
	}
	.close-btn {
		position: absolute;
		top: 15px;
		right: 45px;
		background-color: transparent;
		border: 0;
		color: $white;
		font-size: 30px;
		z-index: 1;
		@media (max-width: $max-sm) {
			display: none;
		}
	}
	.btn {
		background-color: transparent;
		color: $white;
		font-size: 40px;
		padding: 0;
		@media (max-width: $max-sm) {
			font-size: 30px;
			right: 0;
		}
	}
	input {
		flex: 1;
		background-color: transparent;
		border-bottom: solid $white;
		border-width: 0 0 2px 0;
		font-size: 35px;
		color: $white;
		height: auto;
		font-weight: 700;
		padding-left: 0;
		@media  (max-width: $min-xs) {
			font-size: 20px; 
			padding: 15px 30px 15px 0;
		}
		@media  (max-width: $max-sm) {
			font-size: 20px; 
		}
		@media (min-width: $min-sm ) {
			padding: 15px 50px 15px 0;
		}
		&:hover, &:focus {
			box-shadow: none; 
		}
		&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
			color: $white;
			opacity: 1;
		}
		&::-moz-placeholder { /* Firefox 19+ */
			color: $white;
			opacity: 1;
		}
		&:-ms-input-placeholder { /* IE 10+ */
			color: $white;
			opacity: 1;
		}
		&:-moz-placeholder { /* Firefox 18- */
			color: $white;
			opacity: 1;
		}
	}

}
