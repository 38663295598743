body {
  overflow-y: scroll;
  font-family: "Lato", sans-serif;
  font-size: 16px;
  color: #555;
  .main-wrapper {
    display: flex;
    flex-flow: column;
    min-height: 100vh;
  }
  .to-load {
    flex-grow: 1;
  }
}
.container {
  @media (max-width: $max-md) {
    width: 100%;
  }
}
.to-load {
  overflow: hidden;
}
a,
a:hover,
a:focus {
  text-decoration: none;
  outline: none;
}
img {
  max-width: 100%;
}
.of-c {
  object-fit: cover;
}
.cb {
  clear: both;
}
// Border
.b0 {
  border: 0 !important;
}
// Border Radius
.br0 {
  border-radius: 0 !important;
}
.rounded {
  border-radius: 100%;
}
// Colors
.tc-grey {
  color: #555;
}
.tc-white {
  color: $white;
}
.tc-blue {
  color: $blue;
}
// Display
.d-flex {
  display: flex;
}
.d-block {
  display: block;
}
.d-ib {
  display: inline-block;
}
.flex-1 {
  flex: 1;
}
/* OVERFLOW */
.o-hidden {
  overflow: hidden;
}
.o-inherit {
  overflow: inherit;
}
/* FONT WEIGHT */
.fw300 {
  font-weight: 300;
}
.fw400 {
  font-weight: 400;
}
.fw600 {
  font-weight: 600;
}
.fw700 {
  font-weight: 700;
}
.fw800 {
  font-weight: 800;
}
// Height
.h100 {
  height: 100%;
}
// Line Heights
.lh1-7 {
  line-height: 1.7;
}
.lh30 {
  line-height: 30px;
}

// Padding
.p0 {
  padding: 0 !important;
}
.p5 {
  padding: 5px;
}
.p10 {
  padding: 10px;
}
.p15 {
  padding: 15px;
}
.p20 {
  padding: 20px;
}
.p30 {
  padding: 30px;
}
.p50 {
  padding: 50px;
}
// YX
.p2010 {
  padding: 20px 10px;
}
.p1010 {
  padding: 10px 10px;
}
// X
.px-5 {
  padding: 0 5px;
}
.px-10 {
  padding: 0 10px;
}
.px-15 {
  padding: 0 15px;
}
.px-20 {
  padding: 0 20px;
}
.px-30 {
  padding-left: 30px;
  padding-right: 30px;
}
// Y
.py-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}
.py-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}
.py-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}
// PL
.pl-0 {
  padding-left: 0;
}
// PR
.pr-0 {
  padding-right: 0;
}
// Margin
.m0 {
  margin: 0;
}
// Top
.mt0 {
  margin-top: 0;
}
.mt10 {
  margin-top: 10px;
}
.mt15 {
  margin-top: 15px;
}
.mt20 {
  margin-top: 20px;
}
.mt30 {
  margin-top: 30px;
}
// Bottom
.mb0 {
  margin-bottom: 0 !important;
}
.mb5 {
  margin-bottom: 5px;
}
.mb10 {
  margin-bottom: 10px;
}
.mb15 {
  margin-bottom: 15px;
}
.mb20 {
  margin-bottom: 20px;
}
.mb30 {
  margin-bottom: 30px;
}
.mb35 {
  margin-bottom: 35px;
}
.mb40 {
  margin-bottom: 40px;
}
// X
.row15- {
  margin: 0 -7.5px;
}
.row-5 {
  margin: 0 -5px;
}
.row-10 {
  margin: 0 -10px;
}
.row-20 {
  margin: 0 -20px;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
// Y
.my10 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.my20 {
  margin-top: 20px;
  margin-bottom: 20px;
}
.my40 {
  margin-top: 40px;
  margin-bottom: 40px;
}
// Position
.ps-absolute {
  position: absolute;
}
.ps-relative {
  position: relative;
}
.ps-static {
  position: static;
}

// Text transform
.tt-uppercase {
  text-transform: uppercase;
}
.tt-lowercase {
  text-transform: lowercase;
}
// Width
.w10 {
  width: 10%;
}
.w20 {
  width: 20%;
}
.w30 {
  width: 30%;
}
.w40 {
  width: 40%;
}
.w50 {
  width: 50%;
}
.w60 {
  width: 60%;
}
.w70 {
  width: 70%;
}
.w80 {
  width: 80%;
}
.w90 {
  width: 90%;
}
.w100 {
  width: 100%;
}
// Font Sizes px
.fs14 {
  font-size: 14px;
}
.fs15 {
  font-size: 15px;
}
.fs16 {
  font-size: 16px;
}
.fs17 {
  font-size: 17px;
}
.fs18 {
  font-size: 18px;
}
.fs20 {
  font-size: 20px;
}
.fs22 {
  font-size: 22px;
}
.fs25 {
  font-size: 25px;
}
.fs28 {
  font-size: 28px;
}
.fs29- {
  font-size: 29.5px;
}
.fs30 {
  font-size: 30px;
}
.fs40 {
  font-size: 40px;
}
.fs45 {
  font-size: 45px;
}

// Font sizes pt
.fs16pt {
  font-size: 16pt;
}
.fs22pt {
  font-size: 22pt;
}
.fs25pt {
  font-size: 25pt;
}
.fs30pt {
  font-size: 30pt;
}
// Background colors
.bgc-lightgrey {
  background-color: $lightgrey;
}
.bgc-grey {
  background-color: $grey;
}
.bgc-blue {
  background-color: $blue;
}
.bgc-lightblue {
  background-color: $lblue;
}
.bgc-darkgrey {
  background-color: $darkgrey;
}
.bgc-light-grey {
  background-color: #ebe9e9;
}
// Flex
.ai-c {
  align-items: center;
}
.jc-c {
  justify-content: center;
}
.ff-c {
  flex-flow: column;
}
.fw-w {
  flex-wrap: wrap;
}
.align-item-end {
  align-items: flex-end;
}


// Columns
.xxs-full {
  @media (max-width: $max-xxs) {
    width: 100%;
  }
}
// Background Images
.has-bg-cloudy {
  background: {
    image: url("/img/filinvest/cloudy.jpg");
    size: cover;
    position: center;
  }
  @media (max-width: $max-md) {
    background-size: auto 100%;
    background-position: center top;
  }
}
.cloudy {
  position: absolute;
  left: 0;
  right: 0;
  margin: -15px;
}

// Social Media Buttons
// .social-btn {
// 	height: 35px;
// 	width: 35px;
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// 	font-size: 18px;
// 	margin: 0 7.5px;
// 	transition: all ease 300ms;
// 	&:hover {
// 		color: $white;
// 		margin-top: -5px;
// 		transition: all ease 300ms;
// 	}
// 	&.button-facebook {
// 		background-color: #3B5999;
// 	}
// 	&.button-twitter {
// 		background-color: #3AACD1;
// 	}
// 	&.button-googleplus {
// 		background-color: #C7594C;
// 	}
// }
.social-sharing {
}



@media (max-width: 992px) {
  .wow {
    visibility: visible !important;
    -webkit-animation: none !important;
    -moz-animation: none !important;
    -o-animation: none !important;
    -ms-animation: none !important;
    animation: none !important;
  }
}