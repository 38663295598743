
.node-type-projects {

    .nav__slider.less-slick .slick-track {
        transform: none !important;
    }
    // Global Classes
    .bgc-grey {
        background-color: #e2e2e2;
    }
    .container {
        max-width: 1280px;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
    }
    // To be added
    .field--subdivisions {
        .field-label {
            margin-right: 20px;
        }
        .dropdown-toggle {
            padding: 10px 20px;
            border: 1px solid #e1e1e1;
            color: #555;
            min-width: 245px;
            display: block;
        }
        .dropdown-item {
            padding: 5px 20px;
        }
        .dropdown-menu {
            width: 100%;
        }
        @media screen and (max-width: 767px){
            flex-direction: column;
            .field-label {
                margin-right: 0px;
                padding-bottom: 10px;
            }
        }
    }
    .field--media-links {
        .field-items {
            margin-left: 20px;
        }
        a {
            color: $blue;
            font-size: 20px;
            border-radius: 100%;
            border: 1px solid;
            width: 40px;
            height: 40px;
            justify-content: center;
            display: inline-flex;
            padding: 5px;
            align-items: center;
            margin: 0 5px;
        }

        @media screen and (max-width: 767px){
            flex-direction: column;
        }
    }
    .image-text-block {
        @media screen and (max-width: 767px){
            flex-direction: column;

            .image-content {
                padding-bottom: 10px;
            }
        }
    }

    // Banner
    .block--banner .item img {
        max-height: 750px !important;
    }
    // Data 
    .basic--details { 
        border-bottom: 1px solid #ECECEC;
        .logo {
            display: flex;
            align-items: flex-end;

            img {
                max-height: 130px;
            }
        }
        .has-padding {
            padding: 35px 50px;
            display: flex;
            align-items: flex-start;
            flex-flow: column;
        }
        @media screen and (max-width: 479px){
            padding-top: 20px;
            .container {
                display: block;
            }
            .has-padding {
                padding: 10px 15px;
            }
            // .fs22pt {
            //     font-size: 22px;
            // }
        }
        @media screen and (min-width: 480px) and (max-width: 767px){
            .has-padding {
                padding: 25px 15px;
            }
            .fs22pt {
                font-size: 22px;
            }
        }

        @media screen and (max-width: 767px){
            
            .title {
                font-size: 24px;
            }
            .fs16pt {
                font-size: 13pt;
            }
            .fs22pt {
                font-size: 18pt;
            }

            .logo {
                justify-content: center;

                img {
                    max-height: 100px;
                }
            }

        }
    }

    .slider--wrapper {
        .slick-slider {
            min-height: auto;
        }
        .slick-arrow {
            position: absolute;
            top: 40%;
            padding: 15px;
            background-color: rgba(24, 65, 136, 1);
            color: #fff;
            opacity: .45;
            z-index: 2;

            &:hover {
                opacity: 1;
            }

            &.left {
                left: 0px;
            }

            &.right {
                right: 0px;
            }

            @media screen and (max-width: 757px){
                padding: 10px !important;
                top: 33%;

                &::before {
                    font-size: 36px;
                }


            }
        }
        // .nav__slider {
        //     min-height: auto;
        // }
        .nav-item {
            padding: 0 5px;
            position: relative;

            &:before {
                content: '';
                position: absolute;
                background-color: #fff;
                opacity: .6;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                transition: opacity .3s;
            }

            img {
                height: 100px;
                width: 100%;
                object-fit: cover;

                @media screen and (max-width: 575px){
                    height: 60px;
                }
            }

            &.slick-current {
                &:before {
                    opacity: 0;
                    transition: opacity .3s;
                }
            }
        }
    }

    .block-form {
        padding: 0 15px;
        .captcha {
			transform: scale(.7);
			transform-origin: 0 0;
			display: block;
		}
		.form-textarea {
			height: 100%;
			textarea {
				min-height: 100%;
			}
        }
        
		.form-footer {
			> div {
				flex-grow: 1;
				&:nth-child(1) {
					@media (min-width: $min-md) {
						width: 62%;			
					}
				}
				&:nth-child(2) {
					@media (min-width: $min-md) {
						width: 10%;
					}
				}
			}
        }
        
        @media screen and (max-width: 767px){
            .form-head {
                > div {
                    width: 100%;
                    padding: 5px 0;
                }
            }   
        }
    }

    // floor plan 
    .image--content {
        img {
            border: 1px solid rgb(224, 224, 224);
    height: 350px;
    object-fit: contain;
    width: 100%;
        }
    }
} // End Projects



// Start Webform
.node-type-webform {
    .to-load {
        > hr {
            @media (max-width: $max-sm) {
                display: none;
            }
        }
    }
    address {
        line-height: 1.6;
    }
    .page-header { 
        margin: 35px 0;
    }
    .main-container > .row {
        @media (max-width: $max-md) {
            flex-direction: column-reverse;
            > div {
                &:last-of-type {
                    padding: 0;
                }
            }
        }
    }
    .webform-component {
        margin-bottom: 20px;
        &.webform-component-checkbox {
            margin-bottom: 20px;
        }
        label, .form-control, select, textarea {
            color: #555;
        }
    }
    .main-container {
        .form-control, .bootstrap-select .dropdown-toggle {
            &, &:hover, &:focus { 
                border-radius: 0;
                border: 1px solid #e1e1e1;
                background-color: $white;
                color: #333; 
            } 
        }
    }
    textarea {
        min-height: 85px;
        resize: none;
    }
    .has-danger {
        .form-control, .dropdown-toggle, input[type="checkbox"] + label:before {
            border: 1px solid #f73636 !important;
        }
    }
    .help-block {
        &:empty {
            display: none;
        }
        &.with-errors {
            font-size: 12px;
            color: #f73636;
        }
    }
    .form-actions {
        @media (max-width: $max-md) {
            margin-bottom: 30px;
        }
    }
    // Checkbox Styling
    input[type="checkbox"] { display: none; }
    input[type="checkbox"] + label {
    font-weight: 400;
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    }

    input[type="checkbox"] + label:last-child { margin-bottom: 0; }

    input[type="checkbox"] + label:before {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    border: 1px solid #ccc;
    position: absolute;
    left: 0;
    top: 5px;
    -webkit-transition: all .12s, border-color .08s;
    transition: all .12s, border-color .08s;
    }

    input[type="checkbox"]:checked + label:before {
    width: 10px;
    top: -5px;
    left: 5px;
    border-radius: 0;
    opacity: 1;
    border-top-color: transparent;
    border-left-color: transparent;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    } // End Checkbox Styling
} // End Webform

.advertisement {
    .text-title {
        color: #174398;
    }
    hr {
        border-top: 1px solid #e2e2e2
    }
    .paragraph-block {
        line-height: 1.5;
    }
    .block-form {
        padding: 0 15px;
        .captcha {
			transform: scale(.7);
			transform-origin: 0 0;
			display: block;
		}
		.form-textarea {
			height: 100%;
			textarea {
				min-height: 100%;
			}
        }
        
		.form-footer {
			> div {
				flex-grow: 1;
				&:nth-child(1) {
					@media (min-width: $min-md) {
						width: 62%;			
					}
				}
				&:nth-child(2) {
					@media (min-width: $min-md) {
						width: 10%;
					}
				}
			}
        }
        
        @media screen and (max-width: 767px){
            .form-head {
                > div {
                    width: 100%;
                    padding: 5px 0;
                }
            }   
        }
    }

    .paragraph-item-show {
        &.paragraph-type-carousel {

            .slick-slider {
                min-height: auto;
            }

            .item {
                outline: none;
                margin: 0 15px;
                img {
                    // object-fit: cover;
                    width: 100%;
                    transform-origin: center;
                    height: 400px;

                    @media screen and (max-width: 767px){
                        height: 200px;
                        object-fit: cover;
                    }
                    @media screen and (max-width: 1199px) and (min-width: 768px) {
                            min-height: 200px !important;
                            height: auto;
                    }
                }
                &.slick-slide img {
                    transform: scale(1) translate3d(0px, 0px, 0px);
                    transition: all 300ms ease;
                    
                }
                &.slick-center img {
                    transform: scale(1) translate3d(0px, 0px, 0px);
                    transition: all 300ms ease;
                    box-shadow: 0px 1px 5px 2px #969696;
                }
    
            }

            .slick-arrow {
                opacity: 1;
                background-color: #184188;
                padding: 5px 15px;
                top: 35%;
                @media screen and (max-width: 767px){
                    top: 25%;
                }

                &:before {
                    line-height: normal;
                }

                &.slick-next {
                    right: 23.2%;

                    @media screen and (min-width: 320px) and (max-width: 479px) {
                        top: 10vh;
                        right: 5%;
                    }

                    @media screen and (min-width: 480px) and (max-width: 767px){
                        // right: 5%;
                        top: 10vh;
                        right: 2.5%;
                    }

                    @media screen and (min-width: 768px) and (max-width: 1198px){
                        right: 35.5%;
                        top: 10vh;
                    }

                    @media screen and (width: 1199px) {
                        right: 28.5%;
                        top: 20%;
                    }
                }
                &.slick-prev {
                    left: 23.2%;
                    @media screen and (min-width: 320px) and (max-width: 479px) {
                        top: 10vh;
                        left: 5%;
                    }

                    @media screen and (min-width: 480px) and (max-width: 767px){
                        // left: 5%;
                        top: 10vh;
                        left: 2.5%;
                    }

                    @media screen and (min-width: 768px) and (max-width: 1198px){
                        left: 35.5%;
                        top: 10vh;
                    }

                    @media screen and (width: 1199px) {
                        left: 28.5%;
                        top: 20%;
                    }
                }
            }
          }
    }

    .iltr .text-content {
        padding: 40px 20px 40px 40px;
        line-height: 27px;
    }

    .irtl .text-content {
        padding: 40px 40px 40px 0;
        line-height: 27px;
    }

    @media screen and (max-width: 991px){
        // .container {
        //     max-width: 100%;
        //     width: 100%;
        // }
        .h-slider {
           display: block !important;
           padding: 0 15px !important;
            .item {
                display: inline-block;
                float: left;
                // min-height: 400px;
              
            }
        }

        .iltr .text-content {
            padding: 40px;
        }
    
        .irtl .text-content {
            padding: 40px;
        }
    }
    @media screen and (min-width: 768px) and (max-width: 991px) {
        .h-slider {
            .item {
                // width:50% !important;
                width: 25% !important;
                img {
                    // height: 250px;
                    // object-fit: contain;
                }
            }
        }
    }
    @media screen and (max-width: 767px) {
        .h-slider {
            padding: 0 15px;
            .item {
                width:100% !important;
            }
        }

        // .ads-header {
        //     >.container {
        //         padding-top: 15px;
        //         padding-bottom: 15px;
        //     }

        //     .tagline {
        //         font-size: 20px !important;
        //         margin-bottom: 10px !important;
        //     }

        //     .secondary_tagline {
        //         font-size: 14px !important;
        //     }
        // }
    }

    @media screen and (min-width: 1200px) {
        .slick-slider {
            .slick-slide {
                .block--text {
                    display: none;
                }
    
                &.slick-current {
                    .block--text {
                        display: block;
                    }
                }
            }
        }
    }
    
}