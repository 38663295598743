
lesshat-selector {
    -lh-property: 0; } 
  @-webkit-keyframes rotate{ 0% { -webkit-transform: rotate(0deg);} 100% { -webkit-transform: rotate(360deg);}}
  @-moz-keyframes rotate{ 0% { -moz-transform: rotate(0deg);} 100% { -moz-transform: rotate(360deg);}}
  @-o-keyframes rotate{ 0% { -o-transform: rotate(0deg);} 100% { -o-transform: rotate(360deg);}}
  @keyframes rotate{ 0% {-webkit-transform: rotate(0deg);-moz-transform: rotate(0deg);-ms-transform: rotate(0deg);transform: rotate(0deg);} 100% {-webkit-transform: rotate(360deg);-moz-transform: rotate(360deg);-ms-transform: rotate(360deg);transform: rotate(360deg);}}
  [not-existing] {
    zoom: 1;
  }
  lesshat-selector {
    -lh-property: 0; } 
  @-webkit-keyframes fade{ 0% { opacity: 1;} 50% { opacity: 0.25;}}
  @-moz-keyframes fade{ 0% { opacity: 1;} 50% { opacity: 0.25;}}
  @-o-keyframes fade{ 0% { opacity: 1;} 50% { opacity: 0.25;}}
  @keyframes fade{ 0% { opacity: 1;} 50% { opacity: 0.25;}}
  [not-existing] {
    zoom: 1;
  }
  lesshat-selector {
    -lh-property: 0; } 
  @-webkit-keyframes fade-in{ 0% { opacity: 0;} 100% { opacity: 1;}}
  @-moz-keyframes fade-in{ 0% { opacity: 0;} 100% { opacity: 1;}}
  @-o-keyframes fade-in{ 0% { opacity: 0;} 100% { opacity: 1;}}
  @keyframes fade-in{ 0% { opacity: 0;} 100% { opacity: 1;}}
  [not-existing] {
    zoom: 1; 
}


// .page-loading {
//     position: fixed;
//     top: 0; 
//     left: 0;
//     height: 100%;
//     width: 100%;
//     z-index: 999;
//     background-color: #fff;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     > p {
//         margin: 15em 0 0;
//     }
// }
// .loader-circle {
//     position: absolute;
//     left: 50%;
//     top: 50%;
//     width: 120px;
//     height: 120px;
//     border-radius: 50%;
//     box-shadow: inset 0 0 0 1px rgba(24, 65, 136, .1);
//     margin-left: -60px;
//     margin-top: -60px;
// }
// .loader-line-mask {
//     position: absolute;
//     left: 50%;
//     top: 50%;
//     width: 60px;
//     height: 120px;
//     margin-left: -60px;
//     margin-top: -60px;
//     overflow: hidden;
//     -webkit-transform-origin: 60px 60px;
//     -moz-transform-origin: 60px 60px;
//     -ms-transform-origin: 60px 60px;
//     -o-transform-origin: 60px 60px;
//     transform-origin: 60px 60px;
//     -webkit-mask-image: -webkit-linear-gradient(top, #184188, rgba(0, 0, 0, 0));
//     -webkit-animation: rotate 1.2s infinite linear;
//     -moz-animation: rotate 1.2s infinite linear;
//     -o-animation: rotate 1.2s infinite linear;
//     animation: rotate 1.2s infinite linear;
// }
// .loader-line-mask .loader-line {
//     width: 120px;
//     height: 120px;
//     border-radius: 50%;
//     box-shadow: inset 0 0 0 3px #184188;
// } 

table.restables-clone {
  display: none;
}

@media (max-width: 991px) {
  table.restables-origin {
      display: none;
  }
  
  table.restables-clone {
      display: table;
      tr:nth-child(even) {
        background-color: #f5f5f5;
      }
  }
}

// WOW JS
.alternate:nth-of-type(4n+1) {
    animation-delay: .1s;
}
.alternate:nth-of-type(4n+2) {
    animation-delay: .2s;
}
.alternate:nth-of-type(4n+3) {
    animation-delay: .3s;
}
.alternate:nth-of-type(4n+4) {
    animation-delay: .4s;
}

/* ALERTS */
.alert-dismissable .close, .alert-dismissible .close {
  right: 0;
}
.alert 
{ 
    margin: 20px 0;
    padding: 20px;
    border-radius: 0;
    border-left: solid #eee;
    border-width: 0 0 0 3px;
}

.alert h4
{
    margin-top: 0;
    margin-bottom: 5px;
}
.alert p:last-child
{
    margin-bottom: 0;
}
.alert-message code
{
    background-color: #fff;
    border-radius: 3px;
}
.alert-success
{
    background-color: #F4FDF0;
    border-color: #3C763D;
}
.alert-success h4
{
    color: #3C763D;
}
.alert-danger
{
    background-color: #fdf7f7;
    border-color: #d9534f;
}
.alert-danger h4
{
    color: #d9534f;
}
.alert-warning
{
    background-color: #fcf8f2;
    border-color: #f0ad4e;
}
.alert-warning h4
{
    color: #f0ad4e;
}
.alert-info
{
    background-color: #f4f8fa;
    border-color: #5bc0de;
}
.alert-info h4
{
    color: #5bc0de;
}
.alert-default
{
    background-color: #EEE;
    border-color: #B4B4B4;
}
.alert-default h4
{
    color: #000;
}
.alert-notice
{
    background-color: #FCFCDD;
    border-color: #BDBD89; 
}
.alert-notice h4
{
    color: #444;
}
.user-profile {
    /* NAV-TABS */
.nav-tabs {
    border-bottom: 2px solid #ddd; }
  
  .nav-tabs > li.active > a,
  .nav-tabs > li.active > a:focus,
  .nav-tabs > li.active > a:hover {
    border-width: 0; }
  
  .nav-tabs > li > a {
    color: #666;
    border: none; }
  
  .nav-tabs > li.active > a, 
  .nav-tabs > li > a:hover,
  .nav-tabs > li > a:focus {
    color: #E1A04B !important;
    border: none;
    background: transparent; }
  
  .nav-tabs > li > a::after {
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 2px;
    content: '';
    transition: all 250ms ease 0s;
    transform: scale(0);
    background-color: #E1A04B; }
  
  .nav-tabs > li.active > a::after,
  .nav-tabs > li:hover > a::after,
  .nav-tabs > li > a:focus::after {
    transform: scale(1); }
  
  .tab-nav > li > a::after {
    color: #fff;
    background-color: #21527d; }
  
  .tab-pane {
    padding: 15px 0; }
}

noscript.ns-message {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    color: $blue;
    background-color: $white;
    text-align: center;
}  
.flexslider .item .item--content {
	-webkit-animation: fade-out 1.8s ease-out both 0.3s both;
	        animation: fade-out 1.8s ease-out both 0.3s both;
}
// .flex-active-slide .item--content {-webkit-animation:slide-in-top 2.5s cubic-bezier(.25,.46,.45,.94) 2.5s both;animation:slide-in-top 2.5s cubic-bezier(.25,.46,.45,.94) 0s both}
.flexslider .flex-active-slide .item--content {
	-webkit-animation: fade-in 2s cubic-bezier(0.390, 0.575, 0.565, 1.000) 0.5s both;
	        animation: fade-in 2s cubic-bezier(0.390, 0.575, 0.565, 1.000) 0.5s both;
}

@-webkit-keyframes fade-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
}
  }
  @keyframes fade-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
  
  @-webkit-keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
   
  
// @-webkit-keyframes slide-in-top{0%{-webkit-transform:translateY(-100px);transform:translateY(-100px);opacity:0}100%{-webkit-transform:translateY(0);transform:translateY(0);opacity:1}}@keyframes slide-in-top{0%{-webkit-transform:translateY(-100px);transform:translateY(-100px);opacity:0}100%{-webkit-transform:translateY(0);transform:translateY(0);opacity:1}}
 
// Slide Image Animation
.front .flex-active-slide img {
	-webkit-animation: slide-image-animation 1.5s ease-out alternate-reverse both;
	        animation: slide-image-animation 1.5s ease-out alternate-reverse both;
}

 @-webkit-keyframes slide-image-animation {
    0% {
      -webkit-transform: scale(1) translateY(0);
              transform: scale(1) translateY(0);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
    }
    100% {
      -webkit-transform: scale(1.15) translateY(0);
              transform: scale(1.15) translateY(0);
      -webkit-transform-origin: center;
              transform-origin: center;
    }
  }
  @keyframes slide-animation {
    0% {
      -webkit-transform: scale(1) translateY(0);
              transform: scale(1) translateY(0);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
    }
    100% {
      -webkit-transform: scale(1.15) translateY(0px);
              transform: scale(1.15) translateY(0px);
      -webkit-transform-origin: center;
              transform-origin: center;
    }
  }
   

// --  Paragraphs Show
.paragraph-item-show {
  // Carousel
  &.paragraph-type-carousel {
    .slick-list {
        //overflow: inherit;
    }
    .slick-slider {
      min-height: 500px;

      @media screen and (max-width: 1199px){
        min-height: 200px;
      }
    }
    .slick-arrow {
        position: absolute;
        top: 50%;
        background-color: #0C2144;
        color: #fff;
        min-height: 50px;
        border: 0;
        font-size: 0;
        opacity: .45;
        top: 40%;
        padding: 15px;
        transition: all ease 300ms;
        z-index: 2;

        @media screen and (max-width: 480px){
          top: 30%;
        }
        &:hover {
            opacity: 1;
            transition: all ease 300ms;
        }
        &::before {
            display: inline-block;
            font-family: Fontawesome; 
            font-size: 50px;
        }
        &.slick-prev {
            // left: 420px;
            // left: 30vw;
            left: 20%;
            &::before {
                content: "\f104";
            }

            @media screen and (max-width: 480px){
              left: 5vw;
            }
            @media screen and (min-width: 1440px){
              .node-type-projects & {
                left: 21%;
              }
            }
            @media screen and (min-width: 1200px) and (max-width:1439px){
              .node-type-projects & {
                left: 15vw;
              }
            }
            @media screen and (max-width: 1199px){
              .node-type-projects & {
                left: 5%;
              }
            }
        }
        &.slick-next {
            // right: 420px;
            // right: 30vw;
            right: 20%;
            &::before {
                content: "\f105";
            }
            @media screen and (max-width: 480px){
              right: 5vw;
            }
            @media screen and (min-width: 1440px){
              .node-type-projects & {
                right: 21%;
              }
            }
            @media screen and (min-width: 1200px) and (max-width:1439px){
              .node-type-projects & {
                right: 15vw;
              }
            }
            @media screen and (max-width: 1199px){
              .node-type-projects & {
                right: 5%;
              }
            }
        }
    }
    .item {
        outline: none;
        img {
            // object-fit: cover;
            width: 100%;
            transform-origin: center;
        }
        &.slick-slide img {
            transform: scale(0.90) translate3d(0px, 0px, 0px);
            transition: all 300ms ease;
            height: 440px;
        }
        &.slick-center img {
            transform: scale(1) translate3d(0px, 0px, 0px);
            transition: all 300ms ease;
            box-shadow: 0px 1px 5px 2px #969696;
            height: 500px;
        }

        &.slick-slide {
          .item__watermark {
            left: 50px;
            bottom: 20px;
            transition: all ease 300ms ease;
          }
        }
        &.slick-center {
          .item__watermark {
            left: 10px;
            bottom: 10px;
            transition: all ease 300ms ease;
          }
        }

        

        @media screen and (max-width:1199px){
          padding: 0 5px;
          
          &.slick-slide, &.slick-center {
            img {
              height: auto;
            transform: scale(1);
            }
            .item__watermark {
              left: 10px;
              bottom: 10px;
            }
          }
          
        }

        @media screen and (max-width: 767px){
          padding: 0 5px;

          &.slick-slide img {
            transform: scale(1);
            min-height: 165px;
            max-height: 242px;
            // height: auto;
            object-fit: contain;
          }
        }
    }  
  }
  // Embed 
  &.paragraph-type-embed {
    .embed--content {
        iframe {
            width: 100%;
            height: 450px;
        }
    }
  }
  // Slider

  // Image
  &.paragraph-type-image {
    img {
        object-fit: cover;
    }
    img, .magnific-link {
        border: 1px solid #ccc;
    }
  }
} // End Paragraph Show


// .progressive-image-wrapper {
//     padding: 0 !important;
//     img {
//         position: relative;
//     }
// }




.responsive-tabs.nav-tabs {
  position: relative;
  z-index: 10;
  overflow: visible;
  border-bottom: none;

  @media(min-width: $min-lg) {
    border-bottom: 1px solid #ddd;
  }

  span.fa {
    position: absolute;
    top: 20px;
    right: 22px;
    &.fa-chevron-up {
      display: none;
    }
    @media(min-width: $min-lg) {
      display: none;
    }
  }
  > li {
    display: none;
    float: none;
    text-align: center;
    @media (max-width: $max-lg) {
      width: 100% !important;
    }
    &:last-of-type > a {
      margin-right: 0;
    }

    > a {
      margin-right: 0;
      background: #fff;
      border: 1px solid #DDDDDD;

      @media(min-width: $min-lg) {
        margin-right: 4px;
      }
    }

    &.active {
      display: block;
      a {
        @media(min-width: $min-lg) {
          border-bottom-color: transparent; 
        }
        border: 1px solid #DDDDDD;
        border-radius: 2px;
      }
    }

    @media(min-width: $min-lg) {
      display: block;
      float: left;
    }
    

  }

  &.open {

    span.fa {
      &.fa-chevron-up {
        display: block;
        @media(min-width: $min-lg) {
          display: none;
        }
      }
      &.fa-chevron-down {
        display: none;
      }
    }

    > li {
      display: block;

      a {
        border-radius: 0;
      }

      &:first-of-type a {
        border-radius: 2px 2px 0 0;
      }
      &:last-of-type a {
        border-radius: 0 0 2px 2px;
      }
    }
  }
}