// Link cover
.link-cover {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 3;
  opacity: 0;
}
.to-load:empty {
  display: none !important;
}

// Forms
.form-control {
  border: 1px solid #ccc;
  min-height: 40px;
  border-radius: 0;
  padding: 10px 15px;
  box-shadow: none;
}
// Nicescrolls
.nicescroll-rails {
  z-index: 333 !important;
}
//Dropdowns
.select2-container,
.multiselect__content-wrapper {
  .select2-selection--single {
    display: flex;
    align-items: center;
    .select2-selection__arrow {
      top: auto;
    }
  }
  .nicescroll-rails {
    margin-left: -5px;
    width: 3px !important;
  }
  .nicescroll-cursors {
    width: 10px !important;
    height: 20px !important;
    margin-right: -4px;
  }
}
.multiselect__content-wrapper {
  overflow: hidden !important;
}

// Buttons

.btn-default {
  padding: 10px 15px;
  text-align: center;
  min-width: 100px;
  transition: all ease 300ms;
  &:hover,
  &:focus,
  &:active {
    color: $white;
    background-color: $dblue;
    transition: all ease 300ms;
  }
}
.link-btn {
  padding: 15px 50px;
  border: 1px solid $blue;
  text-align: center;
  color: $blue;
  display: inline-block;
  &:hover {
    background-color: $blue;
    color: $white;
  }
  @media (max-width: $max-sm) {
    min-width: 50%;
    max-width: 100%;
  }
}
.link-btn-blue {
  padding: 15px 50px;
  border: 1px solid $blue;
  text-align: center;
  color: $white;
  display: inline-block;
  background-color: $blue;
}
.link-btn-white {
  padding: 15px 50px;
  border: 2px solid $white;
  text-align: center;
  color: $white;
  display: inline-block;
  &:focus,
  &:hover {
    color: $white;
  }
}

// Header
.page-header {
  border: 0;
}

// Dividers
.divider {
  height: 1px;
  width: 100%;
  margin: 20px 0 30px;
  border: 0;
}

// Sprites
.m-sprites::before {
  background: {
    image: url("/img/filinvest/social-links.svg");
    size: 100%;
    repeat: no-repeat;
  }
}

// IS MOBILE
.isMobile {
  .wow {
    visibility: visible !important;
    -webkit-animation: none !important;
    animation: none !important;
  }
}

// Project Lists
.list-properties {
  > div > .row {
    display: flex;
    flex-wrap: wrap;
  }
  .item--wrapper {
    height: 100%;
  }
  .item__title {
    margin: 0 0 10px;
  }
}

.has-three {
  .item {
    @media (min-width: $min-md) {
      width: 33.33%;
    }
  }
}

// Logos Transparent
.prestige-transparent {
  background-color: #9e662b;
}
.futura-transparent {
  background-color: #ed1f29;
}
.aspire-transparent {
  background-color: #0f3866;
}

.bootstrap-select {
  &:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
    width: 100%;
  }
  .dropdown-menu {
    border: 1px solid #bcceed;
    border-radius: 0;
    max-height: 200px;
  }
  .dropdown-menu > li {
    > a:hover,
    > a:focus {
      background-color: #bcceed;
      color: #184188;
      transition: all ease 300ms;
    }
    &.active > a,
    &.active > a:hover,
    &.active > a:focus {
      background-color: #184188;
      color: #fff;
    }
    &.disabled > a,
    &.disabled > a:hover,
    &.disabled > a:focus {
      background-color: #fff;
      color: #9c9c9c;
    }
  }
  .bs-caret {
    position: absolute;
    right: 15px;
    top: 0;
    .caret {
      border: 0;
      margin: 0;
      position: relative;
      top: 0;
      &::after {
        content: "\f107";
        font-family: Fontawesome;
      }
    }
  }
}

.item__watermark {
  position: absolute;
  bottom: 10px;
  left: 10px;
  font-size: 12px;
  color: $white;
  z-index: 2;
  .block--brands & {
    left: auto;
    right: 10px;
  }
}

// Search
.multiselect--disabled .multiselect__select {
  background: transparent;
}

.alert-notifications {
  position: fixed;
  top: 7em;
  right: 15px;
  min-width: 15%;
}

// Last Word
.last-word {
  > span {
    font-weight: 700;
  }
}

// Letter Spacing
.ls-1 {
  letter-spacing: 1px;
}

.ls-2 {
  letter-spacing: 2px;
}

.zsiq_floatmain,
.zsiq_custommain {
  z-index: 333 !important;
  .zsiq_theme11 .zsiq_off .siqico-chat:before,
  .zsiq_theme11 .zsiq_off .siqico-chat div,
  * {
    color: #353434 !important;
  }
  &.zsiq_floatmain.zsiq_theme11,
  &.zsiq_custommain.zsiq_theme11 {
    bottom: 15%;
    z-index: 333 !important;
  }
}

.multiselect__tag-icon:hover {
  background-color: #003288 !important;
}

// .favorite-remove{
//     position: absolute;
//     right: 0;
//     z-index: 10;
//     padding: 3px 5px;
//     color: #ffffff;
//     background-color: #ec0000;
//     border-radius: 50%;
// }

@media only screen and (min-width: 600px) {
  
  .spaces-commertial-padding{
    padding: 0px 115px 20px;
  }

  .space-commercial-image{
    padding: 0px 100px 40px;
  }
  

}

@media only screen and (max-width: 600px) {
  
/*  .spaces-commertial-padding{
    padding: 0px 115px 20px;
  }
*/
}
