// Category 
.page-categories {

	@media screen and (min-width: 992px){
		.block--banner {
			overflow: hidden;
		}
	}
	
	.title {
		@media (max-width: $max-xxs) {
			font-size: 20px;
		}
	}	
	.page-header {
		font-weight: 700;
		@media (max-width: $max-sm) {
			font-size: 30px;
		}
		@media (max-width: $max-xxs) {
			font-size: 20px;
			white-space: nowrap;
		}
	} 
	.description {
		@media (max-width: $max-sm) {
			padding: 0;
			margin-top: 0;
		}
	}
	
	// Start List Projects
	.list-projects {
		.item {
			@include imageHover;
			@include xxsFull;
			@media (max-width: $max-sm) {
				padding: 1.3px;
				margin: 0;
			}
		}
		.item--details {
			padding: 20px 10px 40px;
		}
		> .container > .row {
			display: flex;
			flex-wrap: wrap;
		}
		
	} // End List Projects

	// Start Contact
	.block--contact {
		.container {
			@media (max-width: $max-lg) {
				width: 100%;
			}
		}
		.captcha {
			transform: scale(.7);
			transform-origin: 0 0;
			display: block;
		}
		.form-textarea {
			height: 100%;
			textarea {
				min-height: 100%;
			}
		}
		.form-footer {
			> div {
				flex-grow: 1;
				&:nth-child(1) {
					@media (min-width: $min-md) {
						width: 61%;			
					}
				}
				&:nth-child(2) {
					@media (min-width: $min-md) {
						width: 10%;
					}
				}
			}
		}
	} // End Contact
}
// Townscapes
.page-townscapes {

	.block--inspired {
		border-right: 1px solid #ddd;
		// Responsive 767
		@media (max-width: $max-sm) {
			border: 0;
			.block-title {
				text-align: center;
				margin: 30px 0 50px;
			}
			.item__rm {
				text-align: center;
			}
			.content--wrapper {
				display: flex;
				flex-wrap: wrap;
			}
		} // End Responsive 767
		.item__rm {
			a {
				display: inline-block;
				line-height: 100%;
			}
		}
	} // End Inspired Homes

	.list-projects {
		.item-flex-wrapper {
			.item {
				&:nth-child(1) {
					flex: 1;
				}
			}
		}
	}

	.block--newsletter { 
		@media (max-width: $max-sm) {
			margin-top: 20px;
			padding-top: 20px;
			border-top: 1px solid #ccc;
			> form {
				max-width: 400px;
				margin: 0 auto;
			}
		}
		.block-title {
			margin-top: 100px;
			@media (max-width: $max-sm) {
				margin-top: 0;
			}
		}
		input {
			border: 1px solid $blue;
		}
		.item__rm {
			@media (max-width: $max-sm) {
				text-align: center;
			}
		}
	}
	
}
// Malls

.page-mall {
	.list-projects {
		.item-flex-wrapper {
			.item {
				&:nth-child(1) {
					flex: 1;
				}
			}
		}
	}

}

// Residentials
.page-residential {
	// Per Group
	.group-item {
		flex-wrap: wrap;
		@media (max-width: $max-xxs) {
			margin-bottom: 0;
		}
		> div {
			@media (max-width: $max-sm) {
				&.list-projects {
					padding: 0 15px !important;
				}
			}
			@media (max-width: $max-xxs) {
				&.brand {
					padding: 3px 6px;
				}
				&.list-projects {
					padding: 0 !important;
					.item {
						padding: 3px 6px;
					}
					// .item--content {
					// 	border-top: 6px solid #fff;
					// }
				}
			}
			
		}
		.brand {
			flex: 1;
			.item--wrapper {
				padding: 30px;
				display: flex;
				flex-flow: column;
				align-items: center;
				justify-content: center;
				border: 2px solid $grey;
				height: 100%;
			}
		}
		.list-projects {
			.item {
				@include imageHover;
			} 
			.item--content {
				padding: 15px 20px;
				@media (min-width: $min-md) {
					min-height: 120px;
				}
			}
		}
	} // End Group
	.footer-info {
		@media (max-width: $max-sm) {
			padding: 0 6px;
		}
	}
	// Inspired Homes
	.block--inspired {
		border-right: 1px solid #ddd;
		// Responsive 767
		@media (max-width: $max-sm) {
			border: 0;
			.block-title {
				text-align: center;
				margin: 30px 0 50px;
			}
			.item__rm {
				text-align: center;
			}
			.content--wrapper {
				display: flex;
				flex-wrap: wrap;
			}
		} // End Responsive 767
		.item__rm {
			a {
				display: inline-block;
				line-height: 100%;
			}
		}
	} // End Inspired Homes
	// Newsletter
	.block--newsletter { 
		@media (max-width: $max-sm) {
			margin-top: 20px;
			padding-top: 20px;
			border-top: 1px solid #ccc;
			> form {
				max-width: 400px;
				margin: 0 auto;
			}
		}
		.block-title {
			margin-top: 100px;
			@media (max-width: $max-sm) {
				margin-top: 0;
			}
		}
		input {
			border: 1px solid $blue;
		}
		.item__rm {
			@media (max-width: $max-sm) {
				text-align: center;
			}
		}
	}
	
	.list-unstyled {
		> li {
			word-break: break-all;
		}
	}
}

// Leisure
.page-leisure {
	.list-projects {
		.item-flex-wrapper {
			.item {
				&:nth-child(1) {
					flex: 1;
				}
			}
		}
		.item--details {
		}
	}
}


// Search
.page-search {
	.page-header { 
		margin: 20px auto 0; 
	}
	.results-title {
		@media (max-width: $max-sm) {
			color: $blue;
			font-size: 25px;
		}
	}
	// Start Search Block
	.block--search {
		.container {
			padding: 20px 15px;
			width: 100%;
			border-bottom: 3px solid $blue;
			position: relative;
			@media (max-width: $max-sm) {
				padding: 0;
			}
		}
		.main-search {
			max-width: 1170px;
			width: 100%;
			margin: 0 auto;
			.multiselect__tags {
				border-radius: 0;
				border: 1px solid #D5DEEC;
			}
			.form-group__wrapper {
				width: 100%;
				@media (max-width: $max-sm) {
					padding: 0;
				}
				> div {
					display: flex;
					@media (max-width: $max-sm) {
						margin: 0;
					}
				}
				.row-5 > div {
					@media (min-width: $min-sm) {
						flex-grow: 1;
						width: 33.33%;
					}
					@media (max-width: $max-sm) {
						padding: 0;
					}
					&.field-component-select {
						@include smFull;
					}
				}
			} 
			#advance-search {
				width: 100%;
				@media (max-width: $max-sm) {
					padding: 0;
				}
			}
			.advance-search {
				margin-top: 20px;
				padding: 0 15px;
				margin: 20px -20px 0;
				@media (max-width: $max-sm) {
					padding: 0;
					margin: 0;
				}
				> div {
					width: 33.33%;
					@media (max-width: $max-sm) {
						padding: 0;
					}
					@include xxsFull;
				}
			}
				
		}  
		.row {
			margin-left: auto;
			margin-right: auto;
			float: none;
			max-width: 1200px; 
			&:last-of-type {
				margin-bottom: 0;
			}
		}
		select, .form-control { 
			border: 1px solid #D5DEEC;
			padding: 10px;
			border-radius: 0; 
		}
		.search-action-area {
			position: absolute;
			display: flex;
			margin: 0 auto;
			left: 0;
			right: 0;
			bottom: -51px;
			align-items: center;
			justify-content: center;
			overflow: hidden;
			height: 50px;
			width: 100%;
			@media (max-width: $max-sm) {
				justify-content: flex-end;
			}
			a { 
				font-size: 0; 
				border-radius: 100%;
				top: -18px;
				display: flex;
				position: relative;
				height: 75px;
				justify-content: center;
				align-items: center;
				width: 80px;
				background-color: $blue;
				color: $white;
				&.advance-option {
					i {
						transition: all ease 500ms;
						transform: rotate(180deg);
					}
				}
				&.collapsed {
					i {
						bottom: -10px;
						transition: all ease 500ms;
						transform: rotate(0);
					}
				}
			}
			i {
				font-size: 30px; 
				position: relative;
				bottom: -15px;
				&::before {
					content: "\f078";
				}
			}

		}
	} // End Search Block
	// Start Nav tabs
	.filter-tabs {
		border: solid #89B0F3;
		border-width: 1px 0;
		margin: 20px 0 30px;
		.nav-tabs {
			padding: 0 13.5px;
			@media (max-width: $max-sm) {
				padding: 0;
			}
			> li {
				margin: 0; 
				display: flex;
				align-items: center;
				padding: 0 1.5px;
				-webkit-clip-path: polygon(15% 0%, 85% 0%, 100% 100%, 0% 100%);
				clip-path: polygon(15% 0%, 85% 0%, 100% 100%, 0% 100%);
				margin-left: -50px;
				// opacity: .4;
				transition: all ease 300ms;

				&:nth-child(1) {
					margin-left: 0px;
					z-index: 3;
				}
				&:nth-child(2) {
					z-index: 2;
				}
				@media (max-width: $max-sm) {
					width: 33.33%;
					padding: 0;
					-webkit-clip-path: polygon(10% 0%, 90% 0%, 100% 100%, 0% 100%);
				clip-path: polygon(10% 0%, 90% 0%, 100% 100%, 0% 100%);
				margin-left: -15px;
				}
				img { 
					max-width: 150px;
					width: 100%;
					@media (max-width: $max-sm) { 
						max-width: 100%;
						width: auto;
					}
				}
				&.active, &:hover, &:focus {
					opacity: 1;
					transition: all ease 300ms;

					.prestige {
						background-color: rgb(158, 102, 43);
					}
					.aspire {
						background-color: rgb(25, 65, 135);
					}
					.futura {
						background-color: rgb(224, 40, 38);
					}
				}
			}
			a { 
				border: 0; 
				display: flex;
				align-items: center;
				height: 100%;
				padding: 5px 50px;
				@media (max-width: $max-sm) {
					width: 100%;
					padding: 5px 15px;
					justify-content: center;
				}

				&.prestige {
					background-color: #C09874;
				}
				&.aspire {
					background-color: #7592AE
				}
				&.futura {
					background-color: #BE9292;
				}
			}
		}	
	} // End Nav Tabs

	// Start Filter results
	.filter-results {
		padding: 0 15px;
		margin: 0 auto 20px;
		.item {
			padding: 5px 15px;
			display: inline-flex;
			align-items: center;
			vertical-align: top;
			border: 1px solid #184188;
			transition: all ease 300ms;
			border-radius: 10px; 
			margin-bottom: 5px;
			i {
				cursor: pointer;
				background-color: #184188;
				padding: 5px 7px;
				margin-left: 30px;
				color: #fff;
				border-radius: 100%;
				justify-content: center;
				display: inline-flex;
				align-items: center;
				font-size: 12px;
			}
		} 
	} // End filter results
	// Start Items
	.item {
		@include imageHover;

		@media (max-width: $max-sm) {
			padding: 1.3px;
			margin: 0;
		}
		.item--details {
			font-weight: 300;
			@media (max-width: 991px) {
				min-height: 150px;
			}
			.field-location{
				margin-top: 2px;
			}
		}
	}
	.pager--show__more {
		@media (max-width: $max-sm) {
			margin-top: 20px;
		}
	}
	// Group Items
	.group-item {
		@media (max-width: $max-lg) {
			margin-bottom: 30px;
			&:last-of-type {
				margin-bottom: 0;
			}
		}
		.slick-slider {
			@media (max-width: $max-sm) {
				padding-bottom: 100px;
			}
			.slick-track {
				float: left;
			}
			.slick-arrow {   
				position: absolute;
				z-index: 1;
				font-size: 0;
				border: 0;
				background-color: transparent;
				padding: 0;
				top: 30%;
				&::before {
					font-family: 'Fontawesome';
					font-size: 70px;
					color: $blue;
					text-shadow: 0 0 3px $white;
				}
				&.slick-prev {
					left: -50px;
					&::before {
						content: "\f104";
					}
				}
				&.slick-next {
					right: -50px;
					&::before {
						content: "\f105";
					}
				}
				&.slick-disabled { 
					opacity: .6;
				}
				@media (max-width: $max-lg) {
					& {
						top: -90px;
					}
					&.slick-prev {
						right: 50px;
    					left: auto;
					}
					&.slick-next {
						right: 10px;
					}
				}
				@media (max-width: $max-sm) {
					& {
						top: 20%;
					}
					&.slick-prev {
						left: 10px;	
					}
					&.slick-next {
						right: 10px;
					} 
				}
				// @media (max-width: $max-xxs) {
				// 	&.slick-prev {
				// 		left: 30%;	
				// 	}
				// 	&.slick-next {
				// 		right: 30%;
				// 	}
				// }
			}
		}

		.brand-image {
			top: 0;
			left: 0;
			width: 100%;
			max-width: 120px;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 10px;
			z-index: 1;
			img {
				width: 100%;
			}
		}
		.item__image, .progressive-image {
			height: 265px;
			max-width: 100% !important;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		} 
	}
	// Start Row Per Brand
	.group-item.active {
		display: flex !important;
		flex-wrap: wrap;
		.item {
			.item--wrapper {
				height: 100%;
			}
		}
	} // End Row Per Brand
}

// Offices
.page-offices {
	.list-projects {
		.item-flex-wrapper {
			.item {
				&:nth-child(1) {
					flex: 1;
				}
			}
		}

		.item__title {
			word-break: break-word;
		}
	}
	// Start Filter Tabs
	.block--tabs {
		.navigation-tabs {
			margin-bottom: 60px;
			border: {
				style: solid;
				width: 1px 0;
				color: #e2e2e2;
			}
			li {
				&.active {
					a {
						background-color: #BDC9DF;
						color: $blue;
						border: {
							style: solid !important;
							width: 0 0 1px 0 !important;
							color: $blue;
						}
						&::before, &::after {
							opacity: 1 !important;
						}
						&::before {
							border-top: 16px solid $blue;
						}
						&::after {
							border-top: 15px solid #BDC9DF;
						}
					}
				}
			}
			a {
				color: #555;
				padding: 20px 50px;
				position: relative;
				min-width: 250px;
				text-align: center;
				border: {
					style: solid;
					width: 0 0 1px 0;
					color: #e2e2e2;
				} 
				margin: 0; 
				&::before {
					content: '';  
					display: inline-block;
					width: 0px;
					height: 0px;
					border-left: 27px solid transparent;
					border-right: 27px solid transparent;
					border-top: 16px solid #e2e2e2;
					position: absolute;
					bottom: -16px; 
					left: 0;
					right: 0;
					margin: 0 auto;
					z-index: 1;
					opacity: 0;
				}
				&::after {
					content: ''; 
					display: inline-block; 
					width: 0px;
					height: 0px;
					border-left: 25px solid transparent;
					border-right: 25px solid transparent;
					border-top: 15px solid #eeeeee;
					position: absolute;
					bottom: -15px;
					left: 0;
					right: 0;
					margin: 0 auto;
					z-index: 1;
					opacity: 0;
				}
				&:hover, &:focus {
					&::before, &::after {
						opacity: 1;
					}
				}
			}
		}
		// Item Results
		// Start For Sale
		.tab-sale {

		} // End Sale
		// Start Lease
		.tab-lease {
			.carousel-caption {
				padding-bottom: 15px;
				right: 20px;
				left: auto;
				bottom: 0;
			}
			.item {

				.project--name {
					color: rgba(255, 255, 255, .7);
					// opacity: .7;
				}
				.item--content {
					.border-right {
						border-right: 1px solid #ccc;
					}
				}
			}
			.type-carousel {
				.carousel-control {
					z-index: 1;
					display: flex;
					align-items: center;
					justify-content: center;
					background-image: none;
					opacity: .7;
					transition: all ease 300ms;
					&:hover {
						opacity: 1;
						transition: all ease 300ms;
					}
					&.left {
						justify-content: flex-start;
					}
					&.right {
						justify-content: flex-end;
					}
					.fa {
						padding: 15px;
						background-color: #184188;
						width: 50px;
						text-align: center; 
						display: flex;
						align-items: center;
						justify-content: center;
						min-height: 70px; 
						font-size: 30px; 
					}
				}
			}
			.type-gallery {
				.item__images {
					display: flex;
					margin: 0 -1.5px;
					flex-wrap: wrap;
				}
				.item__image {
					padding: 0 1.5px;
				}
				.item--content {
					margin-top: 3px;
				}
			}
		} // End Lease
	} // End Filter Tabs
	
	
	// Responsive
	// max-sm
	@media (max-width: $max-sm) {
		.block--tabs .navigation-tabs {
			margin-bottom: 10px;
			.nav-tabs {
				padding: 0;
			}
			li {
				width: 50%;
			}
			a {
				padding: 15px;
				min-width: auto;
				&::before, &::after {
					display: none;
				}
			}
		}
		.tab-content {
			padding: 0;
			.tab-sale {
				> .row {
					margin: 0;
				}
			}
			.tab-lease {
				padding: 0 6px;
				.item__title {
					text-align: left;
				}
				.type-gallery {
					.item__image {
						margin-bottom: 6px;
					}
				}
			}
		}
	} // end-max-sm
	// max-xxs
	@media (max-width: $max-xxs) {

	}
	//
	.global-form {
		padding: 0;
	}
}
// Office Parks
.page-office-parks {
	.content {
		border: 1px solid #ECECEC;
		@media (max-width: $max-lg) {
			> div:first-of-type {
				padding: 15px;
			}
		}
	}
	.global-form {
		padding: 0;
	}
}
.page-corporate, .page-offices {
	.global-form {
		padding: 0;
	}
}


// News 
.page-articles {
	// Start Main Content
	.main-content {
		@media (max-width: $max-md) {
			padding: 0;
		}
		.item {
			@include imageHover;
			&:hover {
				.item--wrapper {
					background-color: #EBFCFF;
					transition: all ease 300ms;
				}
				.item__rm {
					a {
						transition: all ease 300ms;
						background-color: #C4DFEE;
					}
				}
			}
		}
		.item--wrapper {
			height: 100%; 
			display: flex;
			flex-flow: column;
			padding-bottom: 50px;
			transition: all ease 300ms;
			.item--description {
				flex-grow: 1;
				overflow: hidden;

				img {
					display: none;
				}
			}
			.item--details {
				overflow: hidden;
			}
			// p.article--paragraph {
			// 	display: -webkit-box;
			// 	-webkit-box-orient: vertical;
			// 	box-orient: vertical;
			// 	max-height: 315px;
			// 	-webkit-line-clamp: 10;
			// }
			
			.item__rm {
				position: absolute;
				bottom: 0;
				width: 100%;
				left: 0;
				right: 0;
				a {
					display: block;
					width: 100%;
					background-color: #D9D9D9;
					padding: 15px;
					text-align: center;
					transition: all ease 300ms;
				}
			}
		} 
		.item__image, .progressive-image {
			height: 250px;
			max-width: 100% !important;
			img { 
				object-fit: cover; 
				width: 100%;
				height: 100%;
			}
		}
	} // End Main Content
	
}

// Start Sidebar filters

.sidebar-secondary {
	.form-control {
		width: 100%;
	}
	.fb-page {
		padding: 0 0 10px;
		background-color: #F4F5F6;
	}
	.filter {
		margin-bottom: 35px;
		border: 1px solid #ccc;
		i.btn {
			position: absolute;
			right: 0;
		}
	} 
	.form-label {
		margin: 0 0 25px;
		font-weight: 400;
		text-transform: capitalize;
	}
	.dropdown-toggle {
		border-radius: 0;
		background-color: #fff; 
		border: 1px solid #ccc;
		width: 100%;
		text-align: left;
		padding: 10px 5px;
		color: $blue;
	}
} // End Sidebar


// CAREER PAGE
.page-careers {
	&.modal-open {
		overflow: hidden;
	}
	@media (max-width: 991px) {
		&.has-sticky .main-wrapper {
			overflow: hidden;
		}
	}
	.help-block.with-errors {
		word-break: break-word;
		font-size: 14px;
	}
	hr {
		@media (max-width: $max-lg) {
			display: none;
		}
	}
	.g-recaptcha {
		@media (max-width: $max-lg) {
			transform: scale(.7);
			transform-origin: center;
		}
	}
	// 
	@media (max-width: $max-md) {
		.footer {
			margin-top: 0;
		}
	}

	// Start Banner
	.block--banner {
		&::before {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			width: 100%;
			background-color: rgba(0, 0, 0, .2);
		}
		img {
			height: 480px;
			object-fit: cover;
			width: 100%;
			@media (max-width: $max-md) {
				height: 300px;
			}
		}
		.page-header {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
		}
	} // End Banner
	// Career Content
	.block--career-content {
		> .row {
			@media (max-width: $max-md) {
				margin: 0;
				padding: 0 50px;
			}
			@media (max-width: $max-xxs) {
				margin: 0;
				padding: 0 20px;
				.item {
					margin-bottom: 30px;
				}
				.item__title {
					text-align: center;
				}
				.item__image {
					max-width: 100px;
					margin: 0 auto 15px;
				}
			}
		}
		.item__title {
			&:hover {
				a {
					color: #888;
				}
			}
		}
	}
	.block--open-positions {
		.block-title {
			@media (max-width: $max-lg) {
				display: none;
			}
		}
	}
	// End Career Content 
	// Start Navtabs
	.filter--tabs {
		border-bottom: 1px solid #e2e2e2;
		@media (max-width: $max-lg) {
			max-width: 300px;
			border: 1px solid #333;
			margin: 0 auto 30px;
		}
		.nav-tabs {
			border: 0;
			li {
				a {
					margin: 0;
					padding: 15px 25px;
					border: {
						style: solid;
						width: 0 1px 0 0;
						color: #ccc;
					}
				}
				&:first-of-type a {
					border-width: 0 1px 0;
				}
			}
			@media (max-width: $max-lg) {
				margin: 0 auto;
				color: $blue;
				padding: 0;
				width: 100%;
				li {
					&.active a {
						color: $blue;
						height: auto !important;
					}
					a {
						border: 0;
						height: auto !important;
					}
				}
			}
		}
	}
	// End Navtabs
	
	.block--content {
		> .row15- {
			@media (max-width: $max-lg) {
				margin: 0 auto;
			}
			@media (max-width: $max-md) {
				flex-direction: column-reverse;
			}
			> * {
				padding: 0 7.5px; 
				@media (min-width: $min-lg) {
					&.sidebar-primary {
						width: 35%;
					}
					&.content {
						width: 65%;
					}
				}
			}
		}
	}
	// Start Position Items
	.panel-group {
		@media (max-width: $max-md) {
			margin: 0;
		}
	}
	.item-position {
		margin-bottom: 10px;
		.panel-heading {
			padding: 0;
		}
		
		.accordion-toggle {
			padding: 20px 30px;
			background-color: #ececec;
			display: flex;
			flex-flow: column;
			justify-content: center;
			&.collapsed {
				i {
					transform: rotate(180deg);
					transition: all ease 300ms;
				}
			}
			i {
				position: absolute;
				right: 30px;
				transition: all ease 300ms;
				@media (max-width: $max-xxs) {
					top: 28px;
					right: 10px;
				}
			}

		}
		.btn-default {
			padding: 15px;
			background-color: $blue;
			color: $white;
			display: inline-block;
			border-radius: 0;
			margin: 20px 0 0;
			transition: all ease 300ms;
			&:hover, &:focus {
				color: $white;
				background-color: $dblue; 
				transition: all ease 300ms;
			}
		}
	}
	// End Pisition Items

	// Modal
	.modal-dialog {
		@media (min-width: $min-xs) {
			.modal-dialog {
				position: relative;
				width: 80%;
				margin: 10px auto;
			}
		}
		@media (min-width: $min-sm) {
			width: 600px;
		}
		@media (min-width: $min-lg) {
			width: 50%;
		}
	}
	.modal-content {
		border-radius: 0;
		
		.modal-header {
			.close {
				background: #ccc;
				border-radius: 100%;
				display: flex;
				margin: 0;
				text-align: center;
				opacity: 1;
				justify-content: center;
				color: #fff;
				width: 30px;
				height: 31px;
				position: absolute;
				top: 15px;
				right: 15px;
				span {
					margin-top: -2px;
				}
			}
		}
		.modal-title {
			text-align: center;
			@media (max-width: $max-xxs) {
				text-align: left;
				font-size: 14px;
			}
		}
		.modal-body {
			background-color: #f5f5f5;
		}
		.form-required {
			color: red;
		}
		.form-control {
			border: 2px solid #eee;
		}
		.file-upload {
			background-color: transparent;
			border: 1px solid #ccc !important;
			box-shadow: none;
			cursor: pointer;
		}
		
	}
	.file-upload {
		border: 1px solid #ccc;
		height: auto;
		padding: 30px;
	}
	.webform-submit {
		margin-left: 15px;
	}
	// End Modal

	.link-btn-white {
		&:hover {
			background-color: #071d35;
		}
	}

	@media screen and (max-width: 1199px) and (min-width: 992px){
		.field-contact {
			br {
				content: '';
				height: 5px;
				display: block;
			}
		}
		.sticky {
			> div {
				padding: 15px 30px;
				font-size: 16px;
			}
			.link-btn-white {
				margin-top: 10px;
			}
		}
	}
}


// page
.thankyou-page {
	.page-title {
		color: #184188;
	}
    .details-wrapper {
        font-size: 25px;
        color: #555;
        line-height: 32px;
    }
    .btn__back {
        padding: 10px 15px;
        font-size: 16px;
        text-align: center;
        min-width: 100px;
        -webkit-transition: all ease 300ms;
        transition: all ease 300ms;
        border-radius: 0;
    }
    .content-wrapper {
        display: flex;
		align-items: center;
		justify-content: center;
		min-height: 100vh;
	}
	.btn-default {
		background-color: #134781;
		transition: all ease 300ms;
		color: #fff;
		border-radius: 0px;

		&:hover {
			color: #ffffff;
			background-color: #071D35;
			transition: all ease 300ms;
		}
	}
	@media (max-width: $max-sm) {
		.content-wrapper {
            padding-bottom: 50px;
            min-height: 70vh;
        }
		.page-title {
			padding: 30px 0;
		}
	}

	@media (max-width: $max-sm) {
		

        .page-title {
            font-size: 45px;
        }
	}
}
.page-mixed-use {
	.list-projects {
		.item-flex-wrapper {
			.item {
				&:nth-child(1) {
					flex: 1;
				}
			}
		}
	}
}
// .privacy-policy {
// 	.content--wrapper {
// 		width: 750px;

// 		@media only screen and (max-width: 991px) {
// 			width: 100%;
// 		}
// 	}
// }

